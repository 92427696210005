/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/notification/notification.component.ngfactory";
import * as i4 from "./shared/notification/notification.component";
import * as i5 from "./shared/notification/notification.service";
import * as i6 from "./core/spinner/spinner.component.ngfactory";
import * as i7 from "./core/spinner/spinner.component";
import * as i8 from "./core/spinner/spinner.service";
import * as i9 from "./app.component";
import * as i10 from "./core/eupHttpHandler.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "./core/globalSettings.service";
import * as i13 from "./core/routerInterceptor.service";
import * as i14 from "./core/platformDetector.service";
import * as i15 from "./core/appearance.service";
import * as i16 from "./core/eupRoutes.service";
import * as i17 from "./shared/downloadNotification/downloadNotification.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "eup-notification", [], null, null, null, i3.View_NotificationComponent_0, i3.RenderType_NotificationComponent)), i1.ɵdid(4, 245760, null, 0, i4.NotificationComponent, [i5.NotificationService, i1.ComponentFactoryResolver], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "eup-spinner", [], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i1.ɵdid(6, 49152, null, 0, i7.SpinnerComponent, [i8.SpinnerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "body", [], [[8, "lang", 0], [2, "light-theme", null], [2, "windows", null], [2, "ie", null], [2, "edge", null], [2, "is-tablet", null], [2, "iPad", null], [2, "mac", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i9.AppComponent, [i10.EupHttpHandler, i11.TranslateService, i12.GlobalSettingsService, i13.RouterInterceptorService, i14.PlatformDetectorService, i15.AppearanceService, i16.EupRoutesService, i17.DownloadNotificationService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).lang; var currVal_1 = i1.ɵnov(_v, 1).lightThemeClass; var currVal_2 = i1.ɵnov(_v, 1).windowsClass; var currVal_3 = i1.ɵnov(_v, 1).ieClass; var currVal_4 = i1.ɵnov(_v, 1).edgeClass; var currVal_5 = i1.ɵnov(_v, 1).isTabletClass; var currVal_6 = i1.ɵnov(_v, 1).iPadClass; var currVal_7 = i1.ɵnov(_v, 1).macClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
var AppComponentNgFactory = i1.ɵccf("body", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
