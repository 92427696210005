var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var EupToastService = /** @class */ (function () {
    function EupToastService(toastr) {
        this.toastr = toastr;
    }
    EupToastService.prototype.info = function (content, headline, options) {
        this.toastr.info(content, headline, __assign({}, options, { positionClass: 'toast-bottom-left' }));
    };
    EupToastService.ngInjectableDef = i0.defineInjectable({ factory: function EupToastService_Factory() { return new EupToastService(i0.inject(i1.ToastrService)); }, token: EupToastService, providedIn: "root" });
    return EupToastService;
}());
export { EupToastService };
