import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../core/eupRoutes.service';
import { EvxSoftwareOptions } from '../shared/enums';
import * as i0 from "@angular/core";
import * as i1 from "./eupHttpHandler.service";
import * as i2 from "./eupRoutes.service";
var SoftwareOptionsService = /** @class */ (function () {
    function SoftwareOptionsService(http, eupRoutesService) {
        this.http = http;
        this.eupRoutesService = eupRoutesService;
    }
    SoftwareOptionsService.prototype.isSoftwareOptionEnabled$ = function (softwareOptionParams) {
        return this.http
            .get(this.eupRoutesService.userSettings.areScannerSoftwareOptionsAvailableUrl, { params: softwareOptionParams, withCredentials: true }, false, false);
    };
    SoftwareOptionsService.prototype.hasEvx = function (companyId) {
        return this.getEvxSoftwareOptions(companyId).map(function (res) {
            if (res[EvxSoftwareOptions.ShouldEnableEvx]) {
                return res[EvxSoftwareOptions.ShouldDisableNiriWebReviewTool] ? false : true;
            }
            else
                return false;
        });
    };
    SoftwareOptionsService.prototype.hasEvxOrIOC = function (companyId) {
        return this.getEvxSoftwareOptions(companyId).map(function (res) {
            var responseObject = new EvxOrIOCResponse();
            if (res[EvxSoftwareOptions.ShouldEnableEvx]) {
                responseObject.enableNiriReviewTool = res[EvxSoftwareOptions.ShouldDisableNiriWebReviewTool.toString()] ? false : true;
            }
            responseObject.enableIntraoralCameraTool = res[EvxSoftwareOptions.ShouldEnableIntraoralCameraTool.toString()];
            return responseObject;
        });
    };
    SoftwareOptionsService.prototype.getEvxSoftwareOptions = function (companyId) {
        var params = new HttpParams();
        for (var softwareOption in EvxSoftwareOptions) {
            if (!isNaN(Number(softwareOption))) {
                params = params.append('softwareOptions', softwareOption);
            }
        }
        params = params.append('companyId', companyId);
        return this.isSoftwareOptionEnabled$(params);
    };
    SoftwareOptionsService.prototype.isSoftwareOptionExists = function (softwareOptionId, softwareOptions) {
        return softwareOptions && softwareOptions.some(function (so) { return so === softwareOptionId; });
    };
    SoftwareOptionsService.prototype.getCompanySoftwareOptions = function (companyId) {
        return this.http.get(this.eupRoutesService.userSettings.getCompanySoftwareOptionIds + companyId).map(function (res) {
            return res;
        });
    };
    SoftwareOptionsService.ngInjectableDef = i0.defineInjectable({ factory: function SoftwareOptionsService_Factory() { return new SoftwareOptionsService(i0.inject(i1.EupHttpHandler), i0.inject(i2.EupRoutesService)); }, token: SoftwareOptionsService, providedIn: "root" });
    return SoftwareOptionsService;
}());
export { SoftwareOptionsService };
var EvxOrIOCResponse = /** @class */ (function () {
    function EvxOrIOCResponse() {
        this.enableNiriReviewTool = false;
        this.enableIntraoralCameraTool = false;
    }
    return EvxOrIOCResponse;
}());
export { EvxOrIOCResponse };
