import { Component, HostBinding } from '@angular/core';
import { GlobalSettingsService } from './core/globalSettings.service';
import { RouterInterceptorService } from './core/routerInterceptor.service';
import { PlatformDetectorService } from './core/platformDetector.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { EupHttpHandler } from './core/eupHttpHandler.service';
import { EupRoutesService } from './core/eupRoutes.service';
import { AppearanceService } from './core/appearance.service';
import { Appearance } from './shared/enums';

import { Observable } from 'rxjs';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
import { FileInfo } from './shared/downloadNotification/fileInfo.model';

export class AppConfig {
	private static supportedLangs = [
		'de-DE',
		'es-ES',
		'fr-FR',
		'it-IT',
		'ja-JP',
		'ko-KR',
		'th-TH',
		'zh-CN',
		'zh-TW',
		'en-US'
	];

	public static get getSupportedLanguages() {
		return this.supportedLangs;
	}
}

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
	supportedLangs = AppConfig.getSupportedLanguages;
	browserLang = this.translateService.getBrowserLang();
	browserCultureLang = this.translateService.getBrowserCultureLang();
	private files$: Observable<FileInfo[]>;

	@HostBinding('lang') lang = 'en-US';
	@HostBinding('class.light-theme') lightThemeClass = false;

	@HostBinding('class.windows') windowsClass: boolean;
	@HostBinding('class.ie') ieClass: boolean;
	@HostBinding('class.edge') edgeClass: boolean;
	@HostBinding('class.is-tablet') isTabletClass: boolean;
	@HostBinding('class.iPad') iPadClass: boolean;
	@HostBinding('class.mac') macClass: boolean;

	constructor(
		private http: EupHttpHandler,
		private translateService: TranslateService,
		private globalSettingsService: GlobalSettingsService,
		private routerInterceptor: RouterInterceptorService,
		private platformDetector: PlatformDetectorService,
		private appearanceService: AppearanceService,
		private eupRoutesService: EupRoutesService,
		private downloadNotificationService: DownloadNotificationService
	) {
		const globalSettings = globalSettingsService.get();

		routerInterceptor.initialize();
		platformDetector.initialize();

		this.windowsClass = platformDetector.isWindows;
		this.ieClass = platformDetector.isIE;
		this.edgeClass = platformDetector.isEdge;
		this.isTabletClass = platformDetector.isTablet;
		this.iPadClass = platformDetector.isiPad;
		this.macClass = platformDetector.isMac;

		this.translateService.setDefaultLang('en-US');

		// try to set the language according to the selected language of the logged in user
		if (globalSettings && globalSettings.selectedLanguage) {
			this.setLang(globalSettings.selectedLanguage.code);
		} else {
			// n order to detect browser language for ie & edge needs http request
			if (platformDetector.isIE || platformDetector.isEdge) {
				this.setLang('en-US');
				// const getLangCode = () =>
				// 	this.http.get(this.eupRoutesService.login.browserLanguageCode, undefined, false, false);
				// this.eupRoutesService.doAfterInit(getLangCode).subscribe(
				// 	(res: any) => {
				// 		this.setLang(res.detectedCulture);
				// 	},
				// 	() => {
				// 		this.setLang('en-US');
				// 	}
				// );
			} else {
				if (this.browserCultureLang && this.supportedLangs.indexOf(this.browserCultureLang) > -1) {
					this.setLang(this.browserCultureLang);
				} else {
					if (
						this.browserLang &&
						this.supportedLangs.filter((sl) => sl.substring(0, 2) === this.browserLang)[0]
					) {
						this.setLang(this.supportedLangs.filter((sl) => sl.substring(0, 2) === this.browserLang)[0]);
					}
				}
			}
		}

		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			if (event.lang) {
				this.lang = event.lang;
			}
		});

		this.appearanceService.appearanceChanged$.subscribe((theme) => {
			this.lightThemeClass = theme === Appearance.Light;
		});

		this.files$ = this.downloadNotificationService.getObservable();
	}

	private setLang(lang: string): void {
		this.lang = lang;
		const globalSettings = this.globalSettingsService.get();
		if (globalSettings && globalSettings.selectedLanguage) {
			this.translateService.use(globalSettings.selectedLanguage.code);
		} else {
			this.translateService.use(this.lang);
		}
	}
}

export class BrowserInfo {
	detectedCulture: string;
}
