import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { RouterInterceptorService } from '../../core/routerInterceptor.service';
import { SettingsModal } from '../../settings/settings.component';
import { Utils } from '../utils.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { AccessibilityService } from '../accessibility.service';
import { MessagesService } from '../../messages/messages.service';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { DownloadNotification } from '../downloadNotification/downloadNotification.component';
import { AuthService } from '../../core/auth.service';
import { LocalStorageService } from '@core/localStorage.service';
import { Consts } from '@shared/consts';
var StickyHeaderComponent = /** @class */ (function () {
    function StickyHeaderComponent(router, http, globalSettingsService, utils, routerInterceptor, googleAnalyticsService, eupRoutesService, route, accessibilityService, messagesService, downloadNotificationService, authService, localStorageService) {
        this.router = router;
        this.http = http;
        this.globalSettingsService = globalSettingsService;
        this.utils = utils;
        this.routerInterceptor = routerInterceptor;
        this.googleAnalyticsService = googleAnalyticsService;
        this.eupRoutesService = eupRoutesService;
        this.route = route;
        this.accessibilityService = accessibilityService;
        this.messagesService = messagesService;
        this.downloadNotificationService = downloadNotificationService;
        this.authService = authService;
        this.localStorageService = localStorageService;
        this.backImage = 'backButton46';
        this.messagesCount = 0;
        this.isLogoutOpen = false;
        this.isLinksMenuOpen = false;
        this.isDoctorsMenuOpen = false;
        this.isCompaniesMenuOpen = false;
        this.titleText = '';
        this.image = StickyHeaderComponent.homeImage;
        this.backLink = StickyHeaderComponent.homeLink;
        this.backToLastPage = false;
        this.disableContactSelection = false;
        this.hideContactSelection = false;
        this.disableSiteNavigation = 'false';
        this.isBackLinkAbsoluteUrl = 'false';
        this.shouldAddRoleToBackLink = 'true';
        this.storage = localStorageService.storage;
    }
    Object.defineProperty(StickyHeaderComponent.prototype, "hasFullOrdersVisibility", {
        get: function () {
            return this.settings.hasFullOrdersVisibility;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "username", {
        get: function () {
            return this.settings.username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "disableSiteNavigationFunc", {
        get: function () {
            return this.disableSiteNavigation === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "isBackLinkAbsoluteUrlFunc", {
        get: function () {
            return this.isBackLinkAbsoluteUrl === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "shouldAddRoleToBackLinkFunc", {
        get: function () {
            return this.shouldAddRoleToBackLink === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "isCompanyDropdownDisabled", {
        get: function () {
            return this.disableContactSelection || this.companies.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "isDoctorDropdownDisabled", {
        get: function () {
            return (!this.hasFullOrdersVisibility ||
                this.disableContactSelection ||
                this.doctors.length === 1 ||
                this.disableSiteNavigationFunc);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StickyHeaderComponent.prototype, "settings", {
        get: function () {
            return this.globalSettingsService.get();
        },
        enumerable: true,
        configurable: true
    });
    StickyHeaderComponent.prototype.toggleLogoutMenu = function () {
        this.isLogoutOpen = !this.isLogoutOpen;
    };
    StickyHeaderComponent.prototype.hideLogoutMenu = function () {
        this.isLogoutOpen = false;
    };
    StickyHeaderComponent.prototype.toggleLinksMenu = function () {
        this.isLinksMenuOpen = !this.isLinksMenuOpen;
    };
    StickyHeaderComponent.prototype.hideLinksMenu = function () {
        this.isLinksMenuOpen = false;
    };
    StickyHeaderComponent.prototype.ngOnDestroy = function () {
        this.utils.clearObservablesSubscriptions(this);
    };
    StickyHeaderComponent.prototype.hideDoctorMenu = function () {
        this.isDoctorsMenuOpen = false;
    };
    StickyHeaderComponent.prototype.hideCompaniesMenu = function () {
        this.isCompaniesMenuOpen = false;
    };
    StickyHeaderComponent.prototype.hideDoctorAndCompaniesMenu = function () {
        this.isDoctorsMenuOpen = false;
        this.isCompaniesMenuOpen = false;
    };
    StickyHeaderComponent.prototype.companiesMenuToggled = function (isOpened) {
        if (isOpened) {
            this.hideLinksMenu();
            this.hideLogoutMenu();
            this.downloadNotification.onClose();
        }
        this.isCompaniesMenuOpen = isOpened;
    };
    StickyHeaderComponent.prototype.doctorsMenuToggled = function (isOpened) {
        if (isOpened) {
            this.hideLinksMenu();
            this.hideLogoutMenu();
            this.downloadNotification.onClose();
        }
        this.isDoctorsMenuOpen = isOpened;
    };
    StickyHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        var selectedCompanyId = +this.settings.selectedCompanyId;
        this.companies = this.settings.companies;
        this.rolePath = this.globalSettingsService.rolePath();
        if (!this.isBackLinkAbsoluteUrlFunc && this.shouldAddRoleToBackLinkFunc) {
            this.backLink = this.rolePath + '/' + this.backLink;
        }
        this.selectedCompany = this.getCompanyById(selectedCompanyId);
        this.redirectIfRequired(this.selectedCompany);
        this.fillDoctors(selectedCompanyId);
        this.contextChangedSubscription = this.globalSettingsService.contextChanged.subscribe(function (params) {
            if (!params.companyId || !params.doctorId) {
                return;
            }
            _this.onCompanyChanged(_this.getCompanyById(+params.companyId));
            _this.onDoctorChanged(_this.getDoctorById(+params.doctorId));
        });
        // when this property is set to true - override image and back link
        // according to previous url
        if (this.backToLastPage) {
            var previousRoute = this.routerInterceptor.previousRoute;
            // if no previous url exists (e.g. user entered url in a new tab)
            // or the previous url is home so back button should link to home page
            // or user was redirected to a page from straight from login
            if (!previousRoute ||
                previousRoute === '/' + this.rolePath + '/' + StickyHeaderComponent.homeLink ||
                previousRoute === '/login' ||
                previousRoute === '/') {
                this.backLink = this.rolePath + '/' + StickyHeaderComponent.homeLink;
                this.image = StickyHeaderComponent.homeImage;
            }
            else {
                // a previous url exists and it is not the home page so back button should link
                // to the last page that the user was at
                this.backLink = previousRoute.slice(1);
                this.image = this.backImage;
            }
        }
        this.messagesService.getMessagesCount().subscribe(function (res) {
            _this.messagesCount = res;
        });
        this.preselectCompany();
    };
    StickyHeaderComponent.prototype.logout = function () {
        var _this = this;
        this.http.post(this.eupRoutesService.home.logout).subscribe(function () {
            _this.downloadNotificationService.clear();
            _this.authService.logout();
        });
    };
    StickyHeaderComponent.prototype.onCompanyChanged = function (company) {
        if (this.selectedCompany === company) {
            return;
        }
        this.redirectIfRequired(company);
        this.selectedCompany = company;
        this.globalSettingsService.setCompanyAndDefaultDoctor(company.id);
        this.globalSettingsService.setCompanySoftwareOptions(company.id);
        this.fillDoctors(company.id);
        this.googleAnalyticsService.hitEvent('Home', 'Change Company');
    };
    StickyHeaderComponent.prototype.onDoctorChanged = function (doctor) {
        if (this.selectedDoctor === doctor || doctor === undefined) {
            return;
        }
        this.selectedDoctor = doctor;
        this.globalSettingsService.setDoctor(doctor.contactId);
        this.googleAnalyticsService.hitEvent('Home', 'Change Doctor');
    };
    StickyHeaderComponent.prototype.onImageLinkClick = function () {
        var _this = this;
        if (this.onHomeLinkClickCallback && this.homeLinkClickEvent) {
            if (!this.homeLinkClickSubscription) {
                this.homeLinkClickSubscription = this.homeLinkClickEvent.subscribe(function (res) {
                    _this.router.navigateByUrl("/" + _this.backLink.toLowerCase());
                });
            }
            this.onHomeLinkClickCallback();
        }
        else {
            if (this.isBackLinkAbsoluteUrlFunc) {
                window.location.href = this.backLink;
            }
            else {
                this.router.navigateByUrl("/" + this.backLink.toLowerCase());
            }
        }
    };
    StickyHeaderComponent.prototype.getDoctorById = function (id) {
        return this.doctors.filter(function (c) { return c.contactId === id; })[0];
    };
    StickyHeaderComponent.prototype.getCompanyById = function (id) {
        return this.settings.companies.filter(function (c) { return c.id === id; })[0];
    };
    StickyHeaderComponent.prototype.fillDoctors = function (companyId) {
        var _this = this;
        this.doctors = [{ contactId: this.settings.contactId, contactName: this.settings.username }];
        this.selectedDoctor = this.doctors[0];
        if (this.hasFullOrdersVisibility) {
            this.getContactsSubscription = this.globalSettingsService
                .getContactsForCompany(companyId)
                .subscribe(function (data) {
                _this.doctors = data;
                if (_this.doctors.filter(function (c) { return c.contactId === +_this.settings.selectedDoctorId; }).length > 0) {
                    _this.selectedDoctor = _this.getDoctorById(+_this.settings.selectedDoctorId);
                }
            });
        }
    };
    StickyHeaderComponent.prototype.preselectCompany = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (!params['companyid']) {
                return;
            }
            _this.onCompanyChanged(_this.getCompanyById(+params['companyid']));
        });
    };
    StickyHeaderComponent.prototype.redirectIfRequired = function (company) {
        var _this = this;
        if (!company.handlingEupUrl) {
            return;
        }
        if (company.handlingEupUrl.endsWith('/')) {
            company.handlingEupUrl = company.handlingEupUrl.slice(0, -1);
        }
        var myIteroBffUrl = new URL(this.eupRoutesService.serverUrl);
        var companyMyIteroBffUrl = new URL(company.handlingEupUrl);
        var currentPath = this.accessibilityService.getPathName();
        var myiteroComUrl = new URL(this.accessibilityService.getLocation().origin + "/login?returnUrl=" + currentPath + "&companyId=" + company.id);
        var allowRedirection = myIteroBffUrl.host !== companyMyIteroBffUrl.host;
        if (allowRedirection) {
            this.http.post(this.eupRoutesService.home.logout).subscribe(function () {
                _this.downloadNotificationService.clear();
                _this.globalSettingsService.clear();
                _this.storage[Consts.Storage.RegionalApiEndpoint] = companyMyIteroBffUrl.origin + "/";
                _this.accessibilityService.redirectTo(myiteroComUrl.href);
            });
            return;
        }
    };
    StickyHeaderComponent.homeImage = 'home46';
    StickyHeaderComponent.homeLink = 'home';
    return StickyHeaderComponent;
}());
export { StickyHeaderComponent };
