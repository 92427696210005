var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpParams } from '@angular/common/http';
export var Sorting;
(function (Sorting) {
    Sorting[Sorting["Ascending"] = 0] = "Ascending";
    Sorting[Sorting["Descending"] = 1] = "Descending";
})(Sorting || (Sorting = {}));
var PagedQueryParameters = /** @class */ (function () {
    function PagedQueryParameters() {
        this.existingTotal = this.defaultTotalValue;
    }
    PagedQueryParameters.prototype.createSearchParams = function () {
        var params = new HttpParams();
        if (this.pageNumber) {
            params = params.set('PageNumber', this.pageNumber.toString());
        }
        if (this.pageSize) {
            params = params.set('PageSize', this.pageSize.toString());
        }
        if (this.sort) {
            params = params.set('Sort', this.sort.toString());
        }
        if (this.sortField) {
            params = params.set('SortField', this.sortField);
        }
        if (this.filter) {
            params = params.set('Filter', this.filter);
        }
        if (this.companyId) {
            params = params.set('CompanyId', this.companyId.toString());
        }
        if (this.doctorId) {
            params = params.set('DoctorId', this.doctorId.toString());
        }
        if (this.isNiriFileExist) {
            params = params.set('isNiriFileExist', this.isNiriFileExist.toString());
        }
        if (!isNaN(this.existingTotal)) {
            params = params.set('existingTotal', this.existingTotal.toString());
        }
        return params;
    };
    return PagedQueryParameters;
}());
export { PagedQueryParameters };
var LabOrdersPagedQueryParameters = /** @class */ (function (_super) {
    __extends(LabOrdersPagedQueryParameters, _super);
    function LabOrdersPagedQueryParameters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LabOrdersPagedQueryParameters.prototype.createSearchParams = function () {
        var params = _super.prototype.createSearchParams.call(this);
        if (this.filterMode) {
            params = params.set('filterMode', this.filterMode.toString());
        }
        if (this.dateFormat) {
            params = params.set('dateFormat', this.dateFormat.toString());
        }
        return params;
    };
    return LabOrdersPagedQueryParameters;
}(PagedQueryParameters));
export { LabOrdersPagedQueryParameters };
var ActivityReportPagedQueryParameters = /** @class */ (function (_super) {
    __extends(ActivityReportPagedQueryParameters, _super);
    function ActivityReportPagedQueryParameters() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActivityReportPagedQueryParameters.prototype.createSearchParams = function () {
        var params = _super.prototype.createSearchParams.call(this);
        if (this.fromDate) {
            params = params.set('from', this.fromDate.toUTCString());
        }
        if (this.companyId) {
            params = params.set('to', this.toDate.toUTCString());
        }
        return params;
    };
    return ActivityReportPagedQueryParameters;
}(PagedQueryParameters));
export { ActivityReportPagedQueryParameters };
var PagedQueryResult = /** @class */ (function () {
    function PagedQueryResult() {
    }
    return PagedQueryResult;
}());
export { PagedQueryResult };
var RxRunMode = /** @class */ (function () {
    function RxRunMode() {
    }
    RxRunMode.View = 'View';
    RxRunMode.Edit = 'Edit';
    return RxRunMode;
}());
export { RxRunMode };
var Contact = /** @class */ (function () {
    function Contact() {
    }
    return Contact;
}());
export { Contact };
var ContactWithBusinessPartners = /** @class */ (function () {
    function ContactWithBusinessPartners() {
    }
    return ContactWithBusinessPartners;
}());
export { ContactWithBusinessPartners };
var Company = /** @class */ (function () {
    function Company() {
    }
    return Company;
}());
export { Company };
var Language = /** @class */ (function () {
    function Language() {
    }
    return Language;
}());
export { Language };
var ScannerDetails = /** @class */ (function () {
    function ScannerDetails() {
    }
    return ScannerDetails;
}());
export { ScannerDetails };
var KeyValuePair = /** @class */ (function () {
    function KeyValuePair(key, value) {
        this.key = key;
        this.value = value;
    }
    return KeyValuePair;
}());
export { KeyValuePair };
var ExportRequestDto = /** @class */ (function () {
    function ExportRequestDto() {
    }
    return ExportRequestDto;
}());
export { ExportRequestDto };
var CreateOrthodonticsExportFileRequestDto = /** @class */ (function () {
    function CreateOrthodonticsExportFileRequestDto() {
    }
    return CreateOrthodonticsExportFileRequestDto;
}());
export { CreateOrthodonticsExportFileRequestDto };
var CreateRestorativeExportFileRequestDto = /** @class */ (function () {
    function CreateRestorativeExportFileRequestDto() {
    }
    return CreateRestorativeExportFileRequestDto;
}());
export { CreateRestorativeExportFileRequestDto };
var CreateExportFilesRequestDto = /** @class */ (function () {
    function CreateExportFilesRequestDto() {
    }
    return CreateExportFilesRequestDto;
}());
export { CreateExportFilesRequestDto };
var PasswordPolicyConfig = /** @class */ (function () {
    function PasswordPolicyConfig() {
        this.passwordPolicyNotificationApproved = false;
    }
    return PasswordPolicyConfig;
}());
export { PasswordPolicyConfig };
var ExcelExportOptions = /** @class */ (function () {
    function ExcelExportOptions() {
    }
    return ExcelExportOptions;
}());
export { ExcelExportOptions };
var ChangeCaseTypeEmitData = /** @class */ (function () {
    function ChangeCaseTypeEmitData() {
    }
    return ChangeCaseTypeEmitData;
}());
export { ChangeCaseTypeEmitData };
var UpdatePatientData = /** @class */ (function () {
    function UpdatePatientData() {
    }
    return UpdatePatientData;
}());
export { UpdatePatientData };
export var RxiFrameEvents;
(function (RxiFrameEvents) {
    RxiFrameEvents[RxiFrameEvents["GetAvailableCaseTypes"] = 1] = "GetAvailableCaseTypes";
    RxiFrameEvents[RxiFrameEvents["RenewSession"] = 2] = "RenewSession";
    RxiFrameEvents[RxiFrameEvents["SaveOrderRxForm"] = 3] = "SaveOrderRxForm";
    RxiFrameEvents[RxiFrameEvents["SaveRx"] = 4] = "SaveRx";
    RxiFrameEvents[RxiFrameEvents["LoadConfiguration"] = 5] = "LoadConfiguration";
    RxiFrameEvents[RxiFrameEvents["LoadContactConfiguration"] = 6] = "LoadContactConfiguration";
    RxiFrameEvents[RxiFrameEvents["LoadUserSettings"] = 7] = "LoadUserSettings";
    RxiFrameEvents[RxiFrameEvents["GetRxByOrderId"] = 8] = "GetRxByOrderId";
    RxiFrameEvents[RxiFrameEvents["GetRxById"] = 9] = "GetRxById";
    RxiFrameEvents[RxiFrameEvents["GetPatientByUid"] = 10] = "GetPatientByUid";
    RxiFrameEvents[RxiFrameEvents["CheckPatientExistBy"] = 11] = "CheckPatientExistBy";
    RxiFrameEvents[RxiFrameEvents["FlushInstrumentationLogs"] = 12] = "FlushInstrumentationLogs";
    RxiFrameEvents[RxiFrameEvents["EupHomeClick"] = 13] = "EupHomeClick";
    RxiFrameEvents[RxiFrameEvents["RxSaveEventToiFrameParentNavigateBack"] = 14] = "RxSaveEventToiFrameParentNavigateBack";
    RxiFrameEvents[RxiFrameEvents["RxSaveEventToiFrameParentCanceled"] = 15] = "RxSaveEventToiFrameParentCanceled";
    RxiFrameEvents[RxiFrameEvents["RxLoadCompleted"] = 16] = "RxLoadCompleted";
    RxiFrameEvents[RxiFrameEvents["GetScannerPatientsByDoctor"] = 17] = "GetScannerPatientsByDoctor";
    RxiFrameEvents[RxiFrameEvents["IsAltaEnabled"] = 18] = "IsAltaEnabled";
    RxiFrameEvents[RxiFrameEvents["GetScannerDoctorIdsAddresses"] = 19] = "GetScannerDoctorIdsAddresses";
    RxiFrameEvents[RxiFrameEvents["GetIdsPatientsForCurrentDoctor"] = 20] = "GetIdsPatientsForCurrentDoctor";
    RxiFrameEvents[RxiFrameEvents["GetOrderInformationConfiguration"] = 21] = "GetOrderInformationConfiguration";
    RxiFrameEvents[RxiFrameEvents["GetOrderToSaveAndProceed"] = 22] = "GetOrderToSaveAndProceed";
    RxiFrameEvents[RxiFrameEvents["GetOrderToSaveAndRescan"] = 23] = "GetOrderToSaveAndRescan";
    RxiFrameEvents[RxiFrameEvents["GetOrderToSaveAndProceedToModeling"] = 24] = "GetOrderToSaveAndProceedToModeling";
    RxiFrameEvents[RxiFrameEvents["GetLabNotes"] = 25] = "GetLabNotes";
    RxiFrameEvents[RxiFrameEvents["SetLabNote"] = 26] = "SetLabNote";
    RxiFrameEvents[RxiFrameEvents["RelaodWithoutUserConfirmation"] = 27] = "RelaodWithoutUserConfirmation";
})(RxiFrameEvents || (RxiFrameEvents = {}));
var ExportSettings = /** @class */ (function () {
    function ExportSettings(exportTypeIrecord, dataFormat, fileType, cadCamSystemTypeId) {
        if (exportTypeIrecord === void 0) { exportTypeIrecord = 0; }
        if (dataFormat === void 0) { dataFormat = 0; }
        if (fileType === void 0) { fileType = 0; }
        if (cadCamSystemTypeId === void 0) { cadCamSystemTypeId = 0; }
        this.exportTypeIrecord = exportTypeIrecord;
        this.dataFormat = dataFormat;
        this.fileType = fileType;
        this.cadCamSystemTypeId = cadCamSystemTypeId;
    }
    return ExportSettings;
}());
export { ExportSettings };
var AppConfig = /** @class */ (function () {
    function AppConfig(appSettings) {
        if (appSettings === void 0) { appSettings = new AppSettings(); }
        this.appSettings = appSettings;
    }
    return AppConfig;
}());
export { AppConfig };
var AppSettings = /** @class */ (function () {
    function AppSettings(apiEndpoint, notificationHub) {
        if (apiEndpoint === void 0) { apiEndpoint = 'http://localhost:54386'; }
        if (notificationHub === void 0) { notificationHub = ''; }
        this.apiEndpoint = apiEndpoint;
        this.notificationHub = notificationHub;
    }
    return AppSettings;
}());
export { AppSettings };
