import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { GlobalSettingsService } from './globalSettings.service';

@Injectable()
export class RootGuard implements CanActivate {
	constructor(
		private router: Router,
		private authService: AuthService,
		private globalSettings: GlobalSettingsService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authService.isAuthenticated()) {
			const rolePath = this.globalSettings.rolePath();
			this.router.navigate([ `/${rolePath}/home` ]);
			return true;
		}

		this.router.navigate([ '/login' ]);
		return false;
	}
}
