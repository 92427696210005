var PlatformDetectorService = /** @class */ (function () {
    function PlatformDetectorService() {
    }
    Object.defineProperty(PlatformDetectorService.prototype, "isIE", {
        get: function () {
            return this.isIEVal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformDetectorService.prototype, "isSafari", {
        get: function () {
            return this.isSafariVal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformDetectorService.prototype, "isEdge", {
        get: function () {
            return this.isEdgeVal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformDetectorService.prototype, "isMac", {
        get: function () {
            return this.isMacVal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformDetectorService.prototype, "isWindows", {
        get: function () {
            return this.isWindowsVal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformDetectorService.prototype, "isTablet", {
        get: function () {
            return !this.isMacVal && !this.isWindowsVal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PlatformDetectorService.prototype, "isiPad", {
        get: function () {
            return this.isiPadVal;
        },
        enumerable: true,
        configurable: true
    });
    PlatformDetectorService.prototype.initialize = function () {
        var userAgent = window.navigator.userAgent;
        var platform = window.navigator.platform;
        this.isiPadVal = /iPad/i.test(userAgent);
        this.isEdgeVal = /Edge/i.test(userAgent);
        this.isIEVal = /MSIE|Trident\//i.test(userAgent);
        this.isWindowsVal = /Win32/i.test(platform);
        this.isMacVal = /Mac68K|MacPPC|MacIntel/i.test(platform);
        // Safari Contains just Safari, Chrome contains both
        this.isSafariVal = userAgent.indexOf('Safari') > 1 && userAgent.indexOf('Chrome') === -1;
    };
    return PlatformDetectorService;
}());
export { PlatformDetectorService };
