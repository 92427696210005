﻿import { Component, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon, ModalButton } from '../../shared/notification/notification.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UpdatePatientData } from '../generalInterfaces';
import { PatientsOrdersService } from '../../doctors/patientsOrders/patientsOrders.service';
import { Utils } from '../utils.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
	selector: 'eup-updatePatient',
	templateUrl: './updatePatientModal.component.html',
	styleUrls: ['./updatePatientModal.component.scss']
})
export class UpdatePatientModal implements OnDestroy {
    @ViewChild('updatePatientModal') modalContent: ModalDirective;
    @ViewChild('editPatientForm') public patientFrm: NgForm;
	patientData: UpdatePatientData = new UpdatePatientData();
	patientDataBackup: UpdatePatientData = new UpdatePatientData();
    chartNumberOriginal: string;
    maxInputLength: number;
    noSpaceRegex: string;
	isSaving: boolean;
	hideModal = new Subject<boolean>();
    @Output() onSaved = new EventEmitter<UpdatePatientData>();
	confirmSubscription: Subscription;

    constructor(
        private utils: Utils,
        private patientsOrdersService: PatientsOrdersService,
        private notificationService: NotificationService,
        private translateService: TranslateService
        ) {
        this.maxInputLength = 40;
        this.noSpaceRegex = "^\\S*$";
	    this.isSaving = false;
    }

    show(inputPatientData: UpdatePatientData): Observable<boolean> {
        this.patientData = Object.assign({}, inputPatientData);
        this.patientDataBackup = Object.assign({}, inputPatientData);

		this.modalContent.show();
		return this.hideModal.asObservable();
	}

	hide(shouldUpdate: boolean) {		
        this.hideModal.next(shouldUpdate);		
        this.modalContent.hide();
		this.resetModel();
    }

    hasNewData(): boolean {
        let data = this.patientData;
	    let backup = this.patientDataBackup;
        return !!data // ensure data exists
            && !!backup
	        && !(isEqual(data.chartNumber.trim().toLowerCase(), backup.chartNumber.trim().toLowerCase())
            && isEqual(data.firstName.trim().toLowerCase(), backup.firstName.trim().toLowerCase())
            && isEqual(data.lastName.trim().toLowerCase(), backup.lastName.trim().toLowerCase())
		);
    }

    updateButtonClicked() {
	    this.isSaving = true;
		let notificationServiceSubscription = this.notificationService.show(
            this.translateService.instant('Patients.UpdatePatientPromptTitle'),
            this.translateService.instant('Patients.UpdatePatientPromptText'),
            { buttonCombination: ModalKeys.YesNo,  icon: ModalIcon.Info }
	    ).subscribe((result: any) => {			
            if (result.buttonPressed === ModalButton.Yes) {
	            this.checkPatientExistsAndSave();
            } else {
                this.isSaving = false;
            }
		    notificationServiceSubscription.unsubscribe();
	    });		
    }

	checkPatientExistsAndSave() {
		console.log('checkPatientExistsAndSave');
		this.patientsOrdersService.checkPatientDoesntExistAndUpdate(this.patientData)
			.subscribe((exists: boolean) => {
				if (exists) {
					let notificationServiceSubscription = this.notificationService.show(
						this.translateService.instant('Errors.Error_PatientExists_Title'),
						this.translateService.instant('Errors.Error_PatientExists_Body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					).subscribe((result: any) => {
						this.isSaving = false;
                        notificationServiceSubscription.unsubscribe();
                        });	
				} else {
					this.onSave();
				}
			});	
	}

    onSave() {
	    this.isSaving = false;
		this.hide(true);
		this.onSaved.emit(this.patientData);		
	}

	ngOnDestroy(): void {
		this.resetModel();
	}

    resetModel(): void {
        this.utils.clearObservablesSubscriptions(this);
	}
}
