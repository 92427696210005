import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForbbidenComponent } from './errors/forbidden.component';
import { PageNotFoundComponent } from './errors/pageNotFound.component';
import { InternalServerErrorComponent } from './errors/internalServerError.component';
import { BadRequestComponent } from './errors/badRequest.component';
import { PasswordPolicyGuard } from './core/passwordPolicy/passwordPolicyGuard.service';
import { RolesGuard } from './core/roles/rolesGuard.service';
import { RoleTypeEnum } from './shared/enums';
import { AuthGuard } from './core/auth.guard';
import { RootGuard } from './core/root.guard';

const routes: Routes = [
	{
		path: '',
		canActivateChild: [ AuthGuard ],
		children: [
			{
				path: '',
				canActivate: [ RootGuard ], // guard acts as redirection service here
				children: []
			},
			{
				path: 'doctors',
				loadChildren: './doctors/doctors.module#DoctorsModule',
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				data: {
					expectedRoles: [ RoleTypeEnum.Doctor ]
				}
			},
			{
				path: 'messages',
				loadChildren: './messages/messages.module#MessagesModule',
				canActivate: [ PasswordPolicyGuard ]
			},
			{
				path: 'activityreport',
				loadChildren: './activityReport/activityReport.module#ActivityReportModule',
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				data: {
					expectedRoles: [
						RoleTypeEnum.Doctor,
						RoleTypeEnum.Personnel,
						RoleTypeEnum.Distributor,
						RoleTypeEnum.ManagementCompany
					]
				}
			},
			{
				path: "evx-downloads",
				loadChildren:
					"./evxDownloadsAndResources/evxDownloadsAndResources.module#EvxDownloadsAndResourcesModule"
			},
			{
				path: 'rx',
				loadChildren: './rx/rx.module#RxModule',
				canActivate: [ PasswordPolicyGuard ]
			},
			{
				path: 'personnels',
				loadChildren: './personnels/personnels.module#PersonnelsModule',
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				data: {
					expectedRoles: [ RoleTypeEnum.Personnel ]
				}
			},
			{
				path: 'distributors',
				loadChildren: './distributors/distributors.module#DistributorsModule',
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				data: {
					expectedRoles: [ RoleTypeEnum.Distributor ]
				}
			},
			{
				path: 'managementcompanies',
				loadChildren: './managementCompanies/managementCompanies.module#ManagementCompaniesModule',
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				data: {
					expectedRoles: [ RoleTypeEnum.ManagementCompany ]
				}
			},
			{
				path: 'labs',
				loadChildren: './labs/labs.module#LabsModule',
				canActivate: [ PasswordPolicyGuard, RolesGuard ],
				data: {
					expectedRoles: [ RoleTypeEnum.Lab ]
				}
			}
		]
	},
	{
		path: 'thirdpartylogin',
		loadChildren: './thirdPartyLogin/thirdPartyLogin.module#ThirdPartyLoginModule'
	},
	{
		path: 'logonas',
		loadChildren: './logonAs/logonAs.module#LogonAsModule'
	},
	{
		path: 'downloads',
		loadChildren: './downloadsAndResources/downloadsAndResources.module#DownloadsAndResourcesModule'
	},
	{ path: 'login', loadChildren: './login/login.module#LoginModule' },
	{ path: 'forbidden', component: ForbbidenComponent, pathMatch: 'full', data: { title: '403' } },
	{ path: 'pagenotfound', component: PageNotFoundComponent, pathMatch: 'full', data: { title: '404' } },
	{ path: 'internalservererror', component: InternalServerErrorComponent, pathMatch: 'full', data: { title: '500' } },
	{ path: 'badrequest', component: BadRequestComponent, pathMatch: 'full', data: { title: '400' } },
	{ path: '**', component: PageNotFoundComponent, data: { title: '404' } }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutes {}
