import { Injectable } from '@angular/core';
import { GlobalSettingsService } from './globalSettings.service';
import { Router, Params } from '@angular/router';

@Injectable()
export class AuthService {
	constructor(private globalSettingsService: GlobalSettingsService, private router: Router) {}

	isAuthenticated(): boolean {
		const settings = this.globalSettingsService.get();
		return !!settings;
	}

	logout(queryParams?: Params): void {
		this.globalSettingsService.clear();
		this.router.navigate([ '/login' ], queryParams);
	}
}
