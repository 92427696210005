import { Injectable, EventEmitter } from '@angular/core';
import { LocalStorageService } from './localStorage.service';
import { Company, Contact, Language, PasswordPolicyConfig } from '../shared/generalInterfaces';
import { HttpParams } from '@angular/common/http';
import { EupHttpHandler } from './eupHttpHandler.service';
import { Observable } from 'rxjs';
import { EupRoutesService } from './eupRoutes.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { RoleTypeEnum } from '../shared/enums';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { PrintFormat } from '../shared/enums';
import {
	deLocale as de,
	esLocale as es,
	frLocale as fr,
	itLocale as it,
	jaLocale as ja,
	koLocale as ko,
	zhCnLocale as zhCn,
	trLocale as tr,
	ptBrLocale as ptBr,
	thLocale as th
} from 'ngx-bootstrap/locale';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GeneralExportSettingsData } from '../shared/settings.service';
import { Consts } from '@shared/consts';
import { SoftwareOptionsService } from './softwareOptions.service';

export class ContextParams {
	companyId: number;
	doctorId: number;
	softwareOptions: number[];
}

export class GlobalUISettings {
	public bsDatePickerConfig: BsDatepickerConfig;
}

@Injectable()
export class GlobalSettingsService {
	private settings: GlobalSettings;
	public uiSettings: GlobalUISettings;
	private storage: Storage;
	private http: EupHttpHandler;
	contextChanged = new EventEmitter<ContextParams>();
	private locales = [
		{ code: 'de', locale: de },
		{ code: 'es', locale: es },
		{ code: 'fr', locale: fr },
		{ code: 'it', locale: it },
		{ code: 'ja', locale: ja },
		{ code: 'ko', locale: ko },
		{ code: 'zh', locale: zhCn },
		{ code: 'th', locale: th },
		{ code: 'tr', locale: tr },
		{ code: 'pt', locale: ptBr }
	];

	constructor(
		private translateService: TranslateService,
		localStorageService: LocalStorageService,
		http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private softwareOptionsService: SoftwareOptionsService
	) {
		this.storage = localStorageService.storage;
		this.http = http;
		this.uiSettings = new GlobalUISettings();
	}

	initialize(settings: GlobalSettings): boolean {
		const companyChanged = this.settings && settings.selectedCompanyId !== this.settings.selectedCompanyId;

		let originalLanguage: string;
		let currentLanguage: string;

		if (this.settings && this.settings.selectedLanguage && this.settings.selectedLanguage.code) {
			originalLanguage = this.settings.selectedLanguage.code;
		}

		if (settings && settings.selectedLanguage && settings.selectedLanguage.code) {
			currentLanguage = settings.selectedLanguage.code;
		}

		const languageChanged =
			originalLanguage !== currentLanguage ||
			(this.translateService.currentLang !== undefined && this.translateService.currentLang !== currentLanguage);

		this.settings = settings;
		this.storage.setItem(Consts.Storage.Settings, JSON.stringify(settings));

		if (companyChanged) {
			const params = new ContextParams();
			params.companyId = +this.settings.selectedCompanyId;
			params.doctorId = +this.settings.selectedDoctorId;
			this.contextChanged.emit(params);
		}

		if (languageChanged) {
			this.translateService.use(settings.selectedLanguage !== null ? settings.selectedLanguage.code : 'en-US');
		}

		this.updateDatePickerLocale();

		return companyChanged;
	}

	setCompanyAndDefaultDoctor(companyId: number): Observable<any> {
		const settings = this.get();

		settings.selectedCompanyId = companyId;
		settings.hasFullOrdersVisibility = settings.companies.filter(
			(c) => c.id === +companyId
		)[0].currentUserHasFullOrdersVisibility;

		// we need to clear GeneralExportSettingsData because it is company related.
		settings.generalExportSettingsData = null;

		this.initialize(settings);

		if (settings.hasFullOrdersVisibility) {
			return Observable.fromPromise(
				this.getContactsForCompany(companyId).forEach((data: Contact[]) => {
					settings.selectedDoctorId = settings.contactId;

					const doctorExists = data.filter((c: Contact) => c.contactId === settings.contactId).length === 1;

					if (!doctorExists) {
						settings.selectedDoctorId = data[0].contactId;
					}

					this.setDoctor(settings.selectedDoctorId);
				})
			);
		} else {
			this.setDoctor(settings.contactId);
		}

		return Observable.of(1);
	}

	setCompanySoftwareOptions(companyId: number){
		const settings = this.get();

		this.softwareOptionsService.getCompanySoftwareOptions(companyId).subscribe(res => {
			settings.companySoftwareOptions = res;
			const params = new ContextParams();
			params.softwareOptions = res;

			if(!this.initialize(settings))
			{
				params.companyId = settings.selectedCompanyId;
				params.doctorId = settings.selectedDoctorId;
				this.contextChanged.emit(params);
			}
		});
	}

	clear(): void {
		this.storage.clear();
	}

	setDoctor(doctorId: number): void {
		const settings = this.get();
		settings.selectedDoctorId = doctorId;
		if (!this.initialize(settings)) {
			const params = new ContextParams();
			params.companyId = settings.selectedCompanyId;
			params.doctorId = doctorId;
			this.contextChanged.emit(params);
		}
	}

	setLanguage(language: Language): void {
		const settings = this.get();
		settings.selectedLanguage = language;

		this.initialize(settings);
		this.updateDatePickerLocale();
	}

	getLanguage(): Language {
		
		return this.get().selectedLanguage;
	}

	setDateFormat(format: string): void {
		const settings = this.get();
		settings.dateFormat = format;
		this.initialize(settings);
		this.updateDatePickerLocale();
	}

	setGeneralExportSettingsData(generalExportSettingsData: GeneralExportSettingsData) {
		const settings = this.get();
		settings.generalExportSettingsData = generalExportSettingsData;
		this.initialize(settings);
	}

	setPrintFormat(printFormat: PrintFormat) {
		const settings = this.get();
		settings.printFormat = printFormat;
		this.initialize(settings);
	}

	getPrintformat() {
		const settings = this.get();
		return settings.printFormat;
	}

	getContactsForCompany(companyId: number): Observable<Contact[]> {
		const settings = this.get();
		const company = settings.companies.filter((x) => x.id === companyId)[0];
		if (company && company.contacts) {
			return Observable.of(company.contacts);
		}

		const params = new HttpParams().set('companyId', companyId.toString());

		return this.http.get(this.eupRoutesService.logonAs.getDoctorsByCompanyId, { params: params }).map((res) => {
			company.contacts = res as Contact[];
			this.initialize(settings);
			return company.contacts;
		});
	}

	get(): GlobalSettings {
		if (!this.settings) {
			this.settings = JSON.parse(this.storage.getItem(Consts.Storage.Settings)) as GlobalSettings;
		}
		return this.settings;
	}

	hasElementScanner(): boolean {
		return !!this.settings.companies.filter((c) => c.id === +this.settings.selectedCompanyId)[0]
			.highestScannerVersion;
	}

	public updateDatePickerLocale() {
		if (!this.uiSettings.bsDatePickerConfig) {
			this.uiSettings.bsDatePickerConfig = new BsDatepickerConfig();
		}

		this.uiSettings.bsDatePickerConfig.containerClass = 'theme-blue';
		this.uiSettings.bsDatePickerConfig.showWeekNumbers = false;
		if (this.settings.selectedLanguage && this.settings.selectedLanguage.code.length > 1) {
			const langCode = this.settings.selectedLanguage.code.slice(0, 2);
			const currentLocale = this.locales.find((v) => v.code === langCode);
			if (currentLocale) {
				defineLocale(currentLocale.code, currentLocale.locale);
				Object.assign(this.uiSettings.bsDatePickerConfig, { locale: currentLocale.code });
			}
		}

		if (this.settings.dateFormat) {
			this.uiSettings.bsDatePickerConfig.dateInputFormat = this.settings.dateFormat;
		}
	}

	rolePath(): string {
		const globalSettingsVar = this.get();
		if (!globalSettingsVar) {
			return '';
		}
		const roleType = globalSettingsVar.roleType;
		switch (roleType) {
			case RoleTypeEnum.Distributor:
				return 'distributors';
			case RoleTypeEnum.ManagementCompany:
				return 'managementcompanies';
			case RoleTypeEnum.Doctor:
				return 'doctors';
			case RoleTypeEnum.Lab:
				return 'labs';
			case RoleTypeEnum.Personnel:
				return 'personnels';
			default:
				return '';
		}
	}

	getCompanyRegion(companyId: number): Observable<string> {
		const params = new HttpParams().set('companyId', companyId.toString());
		return this.http.get(this.eupRoutesService.userSettings.getCompanyRegionUrl, { params: params });
	}
}

export class GlobalSettings {
	username: string;
	loginName: string;
	companies: Company[];
	ordersPageSize: number;
	labOrdersPageSize: number;
	contactId: number;
	selectedDoctorId: number;
	patientsPageSize: number;
	selectedCompanyId: number;
	hasFullOrdersVisibility: boolean;
	selectedLanguage: Language;
	dateFormat: string;
	iTeroSiteUrl: string;
	passwordPolicyConfig: PasswordPolicyConfig;
	roleType: RoleTypeEnum;
	loginEmail: string;
	generalExportSettingsData: GeneralExportSettingsData;
	printFormat: PrintFormat;
	companySoftwareOptions: number [];
}
