import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../core/eupRoutes.service';
import { EvxSoftwareOptions } from '../shared/enums';

@Injectable({
	 providedIn: 'root'
})
export class SoftwareOptionsService {
	constructor(private http: EupHttpHandler, private eupRoutesService: EupRoutesService) { }
	isSoftwareOptionEnabled$(softwareOptionParams): Observable<Array<Object>> {
		return this.http
			.get(this.eupRoutesService.userSettings.areScannerSoftwareOptionsAvailableUrl,
				{ params:softwareOptionParams,withCredentials:true },
				false,
				false);
	}

	hasEvx(companyId):Observable<boolean> {
		return this.getEvxSoftwareOptions(companyId).map((res) => {
			if (res[EvxSoftwareOptions.ShouldEnableEvx]) {
				return res[EvxSoftwareOptions.ShouldDisableNiriWebReviewTool] ? false : true;
			} else return false;
		});
	}

	hasEvxOrIOC(companyId): Observable<EvxOrIOCResponse> {
		return this.getEvxSoftwareOptions(companyId).map((res) => {
			const responseObject = new EvxOrIOCResponse();
			if (res[EvxSoftwareOptions.ShouldEnableEvx]) {
				responseObject.enableNiriReviewTool = res[EvxSoftwareOptions.ShouldDisableNiriWebReviewTool.toString()] ? false : true;
			}
			responseObject.enableIntraoralCameraTool = res[EvxSoftwareOptions.ShouldEnableIntraoralCameraTool.toString()];
			return responseObject;
		});
	}

	private getEvxSoftwareOptions(companyId) {
		let params = new HttpParams();
		for (let softwareOption in EvxSoftwareOptions) {
			if (!isNaN(Number(softwareOption))) {
				params = params.append('softwareOptions', softwareOption);
			}
		}
		params = params.append('companyId', companyId);
		return this.isSoftwareOptionEnabled$(params);
	}

	isSoftwareOptionExists(softwareOptionId: number, softwareOptions: number[]): boolean {
		return softwareOptions && softwareOptions.some(so => so === softwareOptionId);
	}
	
	getCompanySoftwareOptions(companyId): Observable<number[]> {
		return this.http.get(this.eupRoutesService.userSettings.getCompanySoftwareOptionIds + companyId).map((res) => {
			return res as number[];
		});
	}
}

export class EvxOrIOCResponse {
	enableNiriReviewTool: boolean = false;
	enableIntraoralCameraTool: boolean = false;
}