import { OnInit, EventEmitter } from '@angular/core';
import { Order, OrdersService } from '../../doctors/orders/orders.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { StorageType } from '../enums';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { DownloadLinkModal } from '../../shared/downloadLinkModal/downloadLinkModal.component';
import { TranslateService } from '@ngx-translate/core';
import { RxService } from '../../rx/rx.service';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { IteroLabService } from '../../shared/iteroLab.service';
var WindowsAppButtonComponent = /** @class */ (function () {
    function WindowsAppButtonComponent(eupRoutesService, orderService, googleAnalyticsService, translateService, rxService, globalSettingsService, iteroLabService) {
        this.eupRoutesService = eupRoutesService;
        this.orderService = orderService;
        this.googleAnalyticsService = googleAnalyticsService;
        this.translateService = translateService;
        this.rxService = rxService;
        this.globalSettingsService = globalSettingsService;
        this.iteroLabService = iteroLabService;
        this.user = "doctor";
        this.windowsOnly = true;
        this.modalAllowed = true;
        this.openModal = new EventEmitter();
    }
    WindowsAppButtonComponent.prototype.ngOnInit = function () {
        this.orthoCadSetupDownloadUrl = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl;
        this.iTeroSetupDownloadUrl = this.eupRoutesService.downloads.iTeroSetupDownloadUrl;
        this.iOSimDownloadUrl = this.eupRoutesService.downloads.iOSimDownloadUrl;
        this.orthoCadSetupDownloadUrl_acs = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl_acs;
        this.iTeroSetupDownloadUrl_acs = this.eupRoutesService.downloads.iTeroSetupDownloadUrl_acs;
        this.iOSimDownloadUrl_acs = this.eupRoutesService.downloads.iOSimDownloadUrl_acs;
        if (!this.title) {
            this.title = this.translateService.instant(this.nameMap[this.application]);
        }
    };
    Object.defineProperty(WindowsAppButtonComponent.prototype, "available", {
        get: function () {
            return this.visibilityMap[this.user][this.application];
        },
        enumerable: true,
        configurable: true
    });
    WindowsAppButtonComponent.prototype.onClick = function () {
        var _this = this;
        this.orderService.getFileStorage(this.order.id).subscribe(function (storage) {
            var appProtocolLink = _this.linkMap[_this.application];
            var downloadLink = _this.installationLinksMap[_this.application][storage];
            var name = _this.nameMap[_this.application];
            _this.googleAnalyticsService.hitEvent('Order', name);
            var title = _this.translateService.instant(name);
            if (_this.iteroLabService.isNeedToSendToLab(_this.order)) {
                _this.iteroLabService.prepareOpenApp(_this.order.id, _this.order.lastDetailsId).subscribe(function (url) {
                    _this.openApp(title, downloadLink, url);
                    _this.orderService.updateLabOrderById(_this.order.id.toString(), _this.globalSettingsService.get().selectedDoctorId.toString(), _this.globalSettingsService.get().selectedCompanyId.toString(), (function (order) {
                        // optimistic UI issue
                        order.canReturnToDoctor = false;
                    }));
                });
            }
            else {
                _this.openApp(title, downloadLink, appProtocolLink);
            }
        });
    };
    WindowsAppButtonComponent.prototype.openApp = function (title, downloadLink, appProtocolLink) {
        this.orderService.startProtocol(appProtocolLink, true);
        if (this.modalAllowed) {
            this.downloadLinkModal.show(title, downloadLink);
        }
        else {
            this.openModal.emit({ title: title, downloadLink: downloadLink });
        }
    };
    Object.defineProperty(WindowsAppButtonComponent.prototype, "customWidth", {
        get: function () {
            return this.widthMap[this.application];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WindowsAppButtonComponent.prototype, "visibilityMap", {
        get: function () {
            return {
                'doctor': {
                    'iosim': this.order.outcomeSimulatorEnabled,
                    'iosim_pa': this.order.progressAssessmentEnabled,
                    'iosim_ca': this.order.iGoCaseAssessmentEnabled,
                    'iosim_go': this.order.iGoOutcomeSimulatorEnabled,
                    'itero': this.order.canOpenFile && !this.order.isOrthoOrder,
                    'orthocad': this.order.canOpenFile && this.order.isOrthoOrder,
                    'export': this.order.canExportFile && !this.order.isOrthoOrder
                },
                'lab': {
                    'orthocad': this.order.canOpenWithOrthoCad,
                    'itero': this.order.canOpenWithItero
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WindowsAppButtonComponent.prototype, "linkMap", {
        get: function () {
            return {
                'iosim': this.order.outcomeSimulatorLink,
                'iosim_pa': this.order.progressAssessmentLink,
                'iosim_ca': this.order.iGoCaseAssessmentLink,
                'iosim_go': this.order.iGoOutcomeSimulatorLink,
                'itero': this.order.openFileLink,
                'orthocad': this.order.openFileLink,
                'export': this.order.exportFileLink
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WindowsAppButtonComponent.prototype, "nameMap", {
        get: function () {
            return {
                'iosim': 'Orders.InvisalignOutcomeSimulator',
                'iosim_pa': 'Orders.InvisalignProgressAssessment',
                'iosim_ca': 'Orders.InvisalignGoCaseAssessment',
                'iosim_go': 'Orders.InvisalignGoOutcomeSimulator',
                'itero': 'Orders.OpenWithiTero',
                'orthocad': 'Orders.OpenWithOrthoCAD',
                'export': 'Orders.ExportWithiTero'
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WindowsAppButtonComponent.prototype, "installationLinksMap", {
        get: function () {
            var _a, _b, _c;
            var iosimLinks = (_a = {},
                _a[StorageType.ACS] = this.iOSimDownloadUrl_acs,
                _a[StorageType.OriginalFMS] = this.iOSimDownloadUrl,
                _a);
            var iTeroLinks = (_b = {},
                _b[StorageType.ACS] = this.iTeroSetupDownloadUrl_acs,
                _b[StorageType.OriginalFMS] = this.iTeroSetupDownloadUrl,
                _b);
            return {
                'iosim': iosimLinks,
                'iosim_pa': iosimLinks,
                'iosim_ca': iosimLinks,
                'iosim_go': iosimLinks,
                'itero': iTeroLinks,
                'orthocad': (_c = {},
                    _c[StorageType.ACS] = this.orthoCadSetupDownloadUrl_acs,
                    _c[StorageType.OriginalFMS] = this.orthoCadSetupDownloadUrl,
                    _c),
                'export': iTeroLinks
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WindowsAppButtonComponent.prototype, "widthMap", {
        get: function () {
            return {
                'iosim': 'extended',
                'iosim_pa': 'extended',
                'iosim_ca': 'extended',
                'iosim_go': 'extended',
                'itero': undefined,
                'orthocad': undefined,
                'export': undefined
            };
        },
        enumerable: true,
        configurable: true
    });
    return WindowsAppButtonComponent;
}());
export { WindowsAppButtonComponent };
