import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IEUPIteroNote, IRxUILabNote } from '../../interfaces/IEUPIteroNote';
import { OrderNotesService } from './service/order-notes.service';

enum IsEdit {
	TRUE = 'true',
	FALSE = 'false'
}

@Component({
	selector: 'eup-itero-orders-notes',
	templateUrl: './iteroOrdersNotes.component.html',
	styleUrls: [ './iteroOrdersNotes.component.scss' ]
})
export class IteroOrdersNotesComponent implements OnInit, OnDestroy {
	public notes: IEUPIteroNote[] = [];

	@Input() addNoteDisabled: boolean;
	public notesLength: number = 0;
	public isEditMode: boolean;

	constructor(private orderNotesService: OrderNotesService) {}

	public toggleEditMode(isEdit?: IsEdit): void {
		this.isEditMode = isEdit ? JSON.parse(isEdit) : !this.isEditMode;
	}

	public addNewNote(newNote: string) {
		this.orderNotesService.AddLabRxNote(newNote);
	}

	private covertHourToPM(strHour: string): string {
		const convertedHour = parseInt(strHour, 10) + 12;
		return `${convertedHour}`;
	}

	private convertToZeroPrefix(strNum: string): string {
		return strNum.length === 1 ? `0${strNum}` : strNum;
	}

	private formatNoteDate(dateToFormat: string): Date {
		dateToFormat = dateToFormat.replace(/\u200e/g, ''); // Removes an occasional unicode character that is embedded in the string

		const noteTimeParts = dateToFormat.split('|');
		const dateSegments = noteTimeParts[0].split('/').map((segment) => segment.trim()); // Date
		const timeSegments = noteTimeParts[1].split(':').map((segment) => segment.trim()); // Time

		const noteMonth = this.convertToZeroPrefix(dateSegments[0]);
		const noteDay = this.convertToZeroPrefix(dateSegments[1]);
		const noteYear = dateSegments[2];

		const noteHours =
			dateToFormat.indexOf('PM') !== -1
				? this.covertHourToPM(timeSegments[0])
				: this.convertToZeroPrefix(timeSegments[0]);
		const noteMinutes = this.convertToZeroPrefix(timeSegments[1]);

		const timeFormat = `${noteYear}-${noteMonth}-${noteDay}T${noteHours}:${noteMinutes}`;

		const noteDate = new Date(timeFormat);

		return noteDate;
	}

	private convertToEUPNote(note: IRxUILabNote): IEUPIteroNote {
		// In case of notes that don't have a date
		const formattedNoteDate: Date | String = note.dateCreated ? this.formatNoteDate(note.dateCreated) : '';

		return {
			name: note.name,
			noteBody: note.content,
			date: formattedNoteDate
		};
	}

	ngOnInit() {
		this.orderNotesService.newNote$.subscribe((notesFromRxUI: IRxUILabNote[]) => {
			if (notesFromRxUI) {
				if (notesFromRxUI.length === 1) {
					const newNote = notesFromRxUI[0];
					let isDuplicateNote: boolean;

					this.notes.forEach((noteItem: IEUPIteroNote) => {
						isDuplicateNote = noteItem.noteBody === newNote.content ? true : isDuplicateNote;
					});
					if (!isDuplicateNote) {
						this.notes.push(this.convertToEUPNote(newNote));
					}
				} else {
					this.notes = notesFromRxUI.map((noteItem) => this.convertToEUPNote(noteItem));
				}

				this.notesLength = this.notes.length;
				this.toggleEditMode(IsEdit.FALSE);
			}
		});
	}

	ngOnDestroy() {
		this.orderNotesService.newNote$.next([]);
	}
}
