import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AccessibilityService } from '../shared/accessibility.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(
		private router: Router,
		private accessibilityService: AccessibilityService,
		private authService: AuthService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivateRoute();
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivateRoute();
	}

	private canActivateRoute(): boolean {
		if (this.authService.isAuthenticated()) {
			return true;
		}

		const relativeLocation = this.accessibilityService.getCurrentRelativeUrl();
		if (!relativeLocation || relativeLocation === '/' || relativeLocation === '/dist') {
			this.router.navigate([ '/login' ]);
		} else {
			this.router.navigate([ '/login' ], { queryParams: { returnUrl: relativeLocation } });
		}
		return false;
	}
}
