import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter } from '@angular/core';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { HttpParams } from '@angular/common/http';
import { RouterInterceptorService } from '../../core/routerInterceptor.service';
import { SettingsModal } from '../../settings/settings.component';
import { Utils } from '../utils.service';
import { GlobalSettingsService, ContextParams, GlobalSettings } from '../../core/globalSettings.service';
import { MessagesService } from '../../messages/messages.service';
import { DownloadNotificationService } from '../downloadNotification/downloadNotification.service';
import { LabCasesState, WindowsApplicationProtocol, BillOfWorkType } from '../enums';
import { RxService } from '../../rx/rx.service';
import { OrdersService, Order } from '../../doctors/orders/orders.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DownloadLinkModal } from '../../shared/downloadLinkModal/downloadLinkModal.component';
import { WindowApplicationLinkData } from '../../shared/windowsApplicationLinkData';
import { TranslateService } from '@ngx-translate/core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { LabExportModal } from '../labExport/labExport.component';
import { StorageType } from '../../shared/enums';
import * as AT from '../../shared/actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
import { IteroLabService } from '../../shared/iteroLab.service';
import { Company } from '@shared/generalInterfaces';
import { AccessibilityService } from '../accessibility.service';
import { Consts } from '@shared/consts';
import { LocalStorageService } from '@core/localStorage.service';

@Component({
	selector: 'eup-lab-sticky-header',
	templateUrl: './labStickyHeader.component.html',
	styleUrls: ['./labStickyHeader.component.scss'],
	providers: [IteroLabService]
})
export class LabStickyHeaderComponent implements OnInit, OnDestroy {
	@ViewChild(LabExportModal) labExportModal: LabExportModal;
	private readonly backImage = 'backButton46';
	public static readonly homeImage = 'home46';
	public static readonly homeLink = 'home';
	private rolePath: string;
	public messagesCount = 0;
	private labCasesState: LabCasesState = undefined;
	private storage: Storage;

	winAppData: WindowApplicationLinkData;
	isLogoutOpen = false;
	isLinksMenuOpen = false;
	disableDownloadScan = false;
	disableActionButtons = true;
	lastBowId?: number = null;
	lastDetailsId?: number = null;
	faExternalLinkAlt = faExternalLinkAlt;

	contextChangedSubscription: Subscription;
	homeLinkClickSubscription: Subscription;
	orderUpdatedEventSubscription: Subscription;

	@Input() titleText = '';
	@Input() image = LabStickyHeaderComponent.homeImage;
	@Input() backLink = LabStickyHeaderComponent.homeLink;
	@Input() backToLastPage = false;
	@Input() showAvatarContainer = 'true';
	@Input() showLinksContainer = 'true';
	@Input() showActionButtons = 'false';
	@Input() onHomeLinkClickCallback: Function;
	@Input() homeLinkClickEvent: EventEmitter<any>;
	@Input() disableSiteNavigation = 'false';
	@Input() isBackLinkAbsoluteUrl = 'false';
	@Input() shouldAddRoleToBackLink = 'true';
	@ViewChild(SettingsModal) settingsModal: SettingsModal;
	@Input() orderId: number;
	@Input() shortOrderData: Order;

	printUrl: SafeResourceUrl;

	public orthoCadSetupDownloadUrl: string;
	public iTeroSetupDownloadUrl: string;
	public orthoCadSetupDownloadUrl_acs: string;
	public iTeroSetupDownloadUrl_acs: string;
	public isOrthoCase: boolean;
	eupObserverSubscription: Subscription;

	@ViewChild(DownloadLinkModal) downloadLinkModal: DownloadLinkModal;

	constructor(
		private router: Router,
		private http: EupHttpHandler,
		public globalSettingsService: GlobalSettingsService,
		private utils: Utils,
		private routerInterceptor: RouterInterceptorService,
		private eupRoutesService: EupRoutesService,
		private route: ActivatedRoute,
		private messagesService: MessagesService,
		private downloadNotificationService: DownloadNotificationService,
		private rxService: RxService,
		public orderService: OrdersService,
		private translateService: TranslateService,
		private eupObserver: EupObserver,
		private iteroLabService: IteroLabService,
		private accessibilityService: AccessibilityService,
		private localStorageService: LocalStorageService
	) {
		this.onPrintUrlChange();
		this.orderUpdatedEventSubscription = this.orderService.OrderUpdated.subscribe((order: Order) => {
			this.updateMembersByOrder(order);
		});
		this.storage = localStorageService.storage;
	}

	onPrintUrlChange(): void {
		this.rxService.printUrlObservable.subscribe((printUrl) => {
			this.printUrl = printUrl;
		});
	}

	get windowsApplicationText(): string {
		this.route.queryParams.forEach((queryParams: Params) => {
			if (queryParams['winappdata']) {
				this.isOrthoCase = queryParams['isOrtho'] === 'true';
			}
		});
		return this.isOrthoCase
			? this.translateService.instant('Lab.OrthoCAD')
			: this.translateService.instant('Lab.iTeroLab');
	}

	get username(): string {
		return this.settings.username;
	}

	get disableSiteNavigationFunc(): boolean {
		return this.disableSiteNavigation === 'true';
	}

	get isBackLinkAbsoluteUrlFunc(): boolean {
		return this.isBackLinkAbsoluteUrl === 'true';
	}

	get shouldAddRoleToBackLinkFunc(): boolean {
		return this.shouldAddRoleToBackLink === 'true';
	}

	private get settings(): GlobalSettings {
		return this.globalSettingsService.get();
	}

	toggleLogoutMenu() {
		this.isLogoutOpen = !this.isLogoutOpen;
	}

	hideLogoutMenu() {
		this.isLogoutOpen = false;
	}

	toggleLinksMenu() {
		this.isLinksMenuOpen = !this.isLinksMenuOpen;
	}

	hideLinksMenu() {
		this.isLinksMenuOpen = false;
	}

	isIteroLab() {
		return !this.isOrthoCase;
	}

	ngOnDestroy(): void {
		this.utils.clearObservablesSubscriptions(this);
		this.eupObserverSubscription.unsubscribe();		
	}

	ngOnInit(): void {
		this.orthoCadSetupDownloadUrl = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl;
		this.iTeroSetupDownloadUrl = this.eupRoutesService.downloads.iTeroSetupDownloadUrl;
		this.orthoCadSetupDownloadUrl_acs = this.eupRoutesService.downloads.orthoCadSetupDownloadUrl_acs;
		this.iTeroSetupDownloadUrl_acs = this.eupRoutesService.downloads.iTeroSetupDownloadUrl_acs;

		this.printUrl = null;
		this.rolePath = this.globalSettingsService.rolePath();
		if (!this.isBackLinkAbsoluteUrlFunc && this.shouldAddRoleToBackLinkFunc) {
			this.backLink = this.rolePath + '/' + this.backLink;
		}
		this.contextChangedSubscription = this.globalSettingsService.contextChanged.subscribe(
			(params: ContextParams) => {
				if (!params.companyId || !params.doctorId) {
					return;
				}
			}
		);

		// as currently supported only 1 company per personnel
		this.redirectIfRequired(this.settings.companies[0]);

		// when this property is set to true - override image and back link
		// according to previous url
		if (this.backToLastPage) {
			let previousRoute = this.routerInterceptor.previousRoute;
			if (previousRoute === '/') {
				previousRoute = previousRoute + this.backLink;
			}
			// if no previous url exists (e.g. user entered url in a new tab)
			// or the previous url is home so back button should link to home page
			// or user was redirected to a page from straight from login
			if (!previousRoute || previousRoute === '/login') {
				this.backLink = this.rolePath + '/' + LabStickyHeaderComponent.homeLink;
				this.image = LabStickyHeaderComponent.homeImage;
			} else {
				// a previous url exists and it is not the home page so back button should link
				// to the last page that the user was at
				this.backLink = previousRoute.slice(1);
				this.image = this.backImage;
			}
		}

		

		this.route.params.forEach((routeParams: Params) => {
			this.labCasesState = routeParams['labCasesState'];
		});

		this.route.queryParams.forEach((queryParams: Params) => {
			if (queryParams['winappdata']) {
				const isOrtho = queryParams['isOrtho'] === 'true',
					name = isOrtho
						? this.translateService.instant('Orders.OpenWithOrthoCAD')
						: this.translateService.instant('Orders.OpenWithiTero');

				this.calculateDownloadLink(isOrtho, queryParams['orderId']).subscribe((downloadLink) => {
					this.isOrthoCase = isOrtho;
					this.winAppData = new WindowApplicationLinkData(
						queryParams['winappdata'],
						name,
						downloadLink,
						queryParams['isOrtho'] === 'true'
							? WindowsApplicationProtocol.IteroLab
							: WindowsApplicationProtocol.OrthoCad
					);
				});
			}

			if (this.orderService.orderObservable$ !== undefined) {
				this.orderService.orderObservable$.subscribe((order: Order) => {
					this.updateMembersByOrder(order);
				});
			}
		});

		this.messagesService.getMessagesCount().subscribe((res: number) => {
			this.messagesCount = res;
		});

		const observer = {
			next: (value) => {
				switch (true) {
					case value.action === AT.WEB_VIEWER_COMPONENT_SAVE_ORDER_PROCEED_TO_MODELING:
					case value.action === AT.RESCAN_COMPONENT_ORDER_RESCAN_SUCCESS:
						this.disableDownloadScan = true;
						break;
					default:
						break;
				}
			}
		};

		this.eupObserverSubscription = this.eupObserver.subscribe(observer);
	}

	private redirectIfRequired(company: Company): void {
		if (!company.handlingEupUrl) {
			return;
		}
		if (company.handlingEupUrl.endsWith('/')) {
			company.handlingEupUrl = company.handlingEupUrl.slice(0, -1);
		}

		const myIteroBffUrl = new URL(this.eupRoutesService.serverUrl);
		const companyMyIteroBffUrl = new URL(company.handlingEupUrl);
		const currentPath = this.accessibilityService.getPathName();
		const myiteroComUrl = new URL(
			`${this.accessibilityService.getLocation().origin}/login?returnUrl=${currentPath}`
		);

		const allowRedirection = myIteroBffUrl.host !== companyMyIteroBffUrl.host;
		if (allowRedirection) {
			this.http.post(this.eupRoutesService.lab.logout).subscribe(() => {
				this.downloadNotificationService.clear();
				this.globalSettingsService.clear();
				this.storage[Consts.Storage.RegionalApiEndpoint] = `${companyMyIteroBffUrl.origin}/`;
				this.accessibilityService.redirectTo(myiteroComUrl.href);
			});
			return;
		}
	}

	private calculateDownloadLink(isOrtho: boolean, orderId: string): Observable<string> {
		const oldLink = isOrtho ? this.orthoCadSetupDownloadUrl : this.iTeroSetupDownloadUrl;
		const newLink = isOrtho ? this.orthoCadSetupDownloadUrl_acs : this.iTeroSetupDownloadUrl_acs;

		if (!orderId || +orderId <= 0) return Observable.of(oldLink);

		const typesMap = {
			[StorageType.ACS]: newLink,
			[StorageType.OriginalFMS]: oldLink
		};

		return this.orderService.getFileStorage(+orderId).map((storage) => typesMap[storage]);
	}

	logout(): void {
		this.http.post(this.eupRoutesService.lab.logout).subscribe(() => {
			this.globalSettingsService.clear();
			this.downloadNotificationService.clear();
			this.router.navigate(['/login']);
		});
	}

	updateMembersByOrder(order: Order) {
		if (order && this.showActionButtons) {
			this.disableActionButtons = false;
			this.disableDownloadScan = !order.canExportFile;
			this.lastBowId = order.lastBowId;
			this.lastDetailsId = order.lastDetailsId;
		}
	}

	onImageLinkClick(): void {
		if (this.onHomeLinkClickCallback && this.homeLinkClickEvent) {
			if (!this.homeLinkClickSubscription) {
				this.homeLinkClickSubscription = this.homeLinkClickEvent.subscribe((res: any) => {
					if (this.labCasesState) {
						if (
							this.backLink.toLowerCase().indexOf('home') !== -1 &&
							this.backLink.toLowerCase().indexOf('q=') !== -1
						) {
							this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
						} else {
							if (
								this.backLink.toLowerCase().indexOf('home') !== -1 &&
								this.backLink.toLowerCase().indexOf('labcasesstate') !== -1
							) {
								this.backLink = this.rolePath + '/' + LabStickyHeaderComponent.homeLink;
							}
							this.router.navigate([`/${this.backLink.toLowerCase()}`], {
								queryParams: { labCasesState: this.labCasesState }
							});
						}
					} else {
						this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
					}
				});
			}
			this.onHomeLinkClickCallback();
		} else {
			if (this.isBackLinkAbsoluteUrlFunc) {
				window.location.href = this.backLink;
			} else {
				if (this.labCasesState) {
					this.router.navigate([`/${this.backLink.toLowerCase()}`], {
						queryParams: { labCasesState: this.labCasesState }
					});
				} else {
					this.router.navigateByUrl(`/${this.backLink.toLowerCase()}`);
				}
			}
		}
	}

	onPrintClicked = () => {
		this.rxService.printOrdersRx(this.orderId.toString()).subscribe();
	}

	onOpenWithiTeroLabClick = () => {
		if (this.iteroLabService.isNeedToSendToLab(this.shortOrderData)) {
			this.iteroLabService.prepareOpenApp(this.orderId, this.lastDetailsId).subscribe((url) => {
				this.onWindowsAppClick(url);
				this.orderService.updateLabOrderById(
					this.orderId.toString(),
					this.globalSettingsService.get().selectedDoctorId.toString(),
					this.globalSettingsService.get().selectedCompanyId.toString(),
					(order: Order) => {
						// optimistic UI issue
						order.canReturnToDoctor = false;
					}
				);
			});

		} else {
			this.onWindowsAppClick();
		}
	}

	onWindowsAppClick(link: string = null) {
		this.orderService.startProtocol(link ? link : this.winAppData.link, true);
		this.downloadLinkModal.show(this.winAppData.name, this.winAppData.downloadLink);
	}

	onExportClick = (): void => {
		const orderId = this.orderId + '';
		const doctorId = this.globalSettingsService.get().selectedDoctorId + '';
		const companyId = this.globalSettingsService.get().selectedCompanyId + '';

		const params = new HttpParams().set('orderId', orderId).set('doctorId', doctorId).set('companyId', companyId);
		this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: params }).subscribe((order) => {
			this.labExportModal.show([order]);
		});
	}
}
