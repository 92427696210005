import { EventEmitter, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { GlobalSettingsService, GlobalSettings } from '../core/globalSettings.service';
import { EupRoutesService } from '../core/eupRoutes.service';
import { RxRunMode } from '../shared/generalInterfaces';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { GoogleAnalyticsService } from '../core/googleAnalytics.service';
import { HttpParams } from '@angular/common/http';
import { RxiFrameEvents } from '../shared/generalInterfaces';
import { DownloadFileService } from '../shared/downloadFile.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon } from '../shared/notification/notification.service';
import { LabRxExtensionService } from './labRxExtension/labRxExtension.service';
import { IRxUILabNote } from '../interfaces/IEUPIteroNote';
import * as JSZip from 'jszip';
import { EupToastService } from '@shared/eupToast.service';
import { ToothNumberingSystem, AssetReferenceType } from '@shared/enums';


@Injectable()
export class RxService implements OnDestroy {
	private http: EupHttpHandler;
	IsSavingRx: boolean;
	IsOrderProceeding: boolean;
	PostMessageEvent = new EventEmitter<any>();
	LabOrderProceededSuccessfullyEvent = new EventEmitter<any>();
	LabOrderRescanSuccessfullyEvent = new EventEmitter<any>();
	labOrderProceedToModelingSucceedEvent = new EventEmitter<any>();
	HomeLinkClickEvent = new EventEmitter<any>();
	ReloadWindow = new EventEmitter<any>();
	isRxIframeLoaded: boolean;
	printUrl: SafeResourceUrl;
	printUrlObservable: BehaviorSubject<any>;
	newNoteObservable$: BehaviorSubject<IRxUILabNote[]>;
	isUpdatingNotes: boolean;
	constructor(
		private globalSettings: GlobalSettingsService,
		private sanitizer: DomSanitizer,
		private notificationService: NotificationService,
		http: EupHttpHandler,
		private translateService: TranslateService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private downloadFileService: DownloadFileService,
		public eupRoutesService: EupRoutesService,
		public labRxExtensionService: LabRxExtensionService,
		private eupToastService: EupToastService
	) {
		this.http = http;
		window.addEventListener('message', this.handleMessageFromRx.bind(this), false);

		this.handlePrintURLChanges();
		this.handleNewNotes();
	}

	ngOnDestroy() {
		window.removeEventListener('message', this.handleMessageFromRx, false);
	}

	private handlePrintURLChanges() {
		this.printUrlObservable = new BehaviorSubject('');
	}

	private handleNewNotes() {
		this.newNoteObservable$ = new BehaviorSubject([]);
	}

	public createParamsListForRx(
		isReadOnly: boolean,
		isRxTakenForScan: boolean,
		settings: GlobalSettings,
		clientVersion?: string,
		id?: string,
		patientGuid?: string,
		toothNumberingTypeId?: ToothNumberingSystem
	): HttpParams {
		let params = new HttpParams()
			.set('Endpoint', this.eupRoutesService.rx.endpointForRx)
			.set('IsReadonly', this.capitalize(isReadOnly.toString()))
			.set('IsRxTakenForScan', this.capitalize(isRxTakenForScan.toString()))
			.set('IsHostedByEUP', 'True')
			.set('RunMode', RxRunMode.Edit.toString());


		if (typeof toothNumberingTypeId != 'undefined') {
			params.set('toothNumberingTypeId', toothNumberingTypeId.toString());
		}

		if (id) {
			if (isNaN(Number(id))) {
				params = params.set('RxID', id);
			} else {
				params = params.set('OrderID', id);
			}
		}

		if (patientGuid) {
			params = params.set('PatientUID', patientGuid);
		}

		if (settings.selectedLanguage && settings.selectedLanguage.code) {
			params = params.set('Language', settings.selectedLanguage.code);
		}

		if (clientVersion) {
			params = params.set('ClientVersion', clientVersion);
		}

		return params;
	}

	public createUrlForRx(
		isReadOnly: boolean,
		isRxTakenForScan: boolean,
		isEvxEnabled: boolean,
		id?: string,
		patientGuid?: string
	): SafeResourceUrl {
		const settings = this.globalSettings.get();
		const clientVersion = settings.companies.filter((c) => c.id === +settings.selectedCompanyId)[0]
			.highestScannerVersion;
		let params = this.createParamsListForRx(isReadOnly, isRxTakenForScan, settings, clientVersion, id, patientGuid);

		params = params.set('ContactID', settings.selectedDoctorId.toString());
		params = params.set('CompanyID', settings.selectedCompanyId.toString());
		params = params.set('ProductType', isEvxEnabled ? 'Evx' : '');
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.eupRoutesService.rx.rxUrl + params.toString());
	}

	public createUrlForLabsRx(
		isReadOnly: boolean,
		isRxTakenForScan: boolean,
		rxDoctorId: string,
		rxCompanyId: string,
		id?: string,
		patientGuid?: string,
		toothNumberingSystem?: ToothNumberingSystem
	): SafeResourceUrl {
		const settings = this.globalSettings.get();
		let params = this.createParamsListForRx(isReadOnly, isRxTakenForScan, settings, undefined, id, patientGuid, toothNumberingSystem);

		params = params.set('ApplicationMode', 'WebRx');
		params = params.set('UserMode', 'Lab');
		params = params.set('ContactID', rxDoctorId);
		params = params.set('CompanyID', rxCompanyId);
		params = params.set('ShowOrderInformationSection', 'False');
		params = params.set('ShowNotesSection', 'False');
		if (typeof toothNumberingSystem != 'undefined') {
			params = params.set('toothNumberingTypeId', toothNumberingSystem.toString());
		}

		const rnd = Math.floor(Math.random() * Math.floor(100000));
		return this.sanitizer.bypassSecurityTrustResourceUrl(
			this.eupRoutesService.rx.webRxUrl + `?rnd=${rnd}#/?` + params.toString()
		);
	}

	private capitalize(s: string): string {
		return s && s[0].toUpperCase() + s.slice(1);
	}

	public getAvailableCaseTypes(contactId: number, companyId: number): Observable<any> {
		const url = `${this.eupRoutesService.rx.getAvailableCaseTypesForRxUrl}/${contactId}?CompanyId=${companyId}`;
		return this.http.get(url);
	}

	public configuration(id: number, isLabOrTechnician: boolean = false, langCode: string): Observable<any> {
		let url = `${this.eupRoutesService.rx.configurationUrl}/${id}?isLabOrTechnician=${isLabOrTechnician}`;
		if (langCode) {
			url += `&LangCode=${langCode}`;
		}
		return this.http.get(url);
	}

	public save(rxModel: any): Observable<any> {
		const url = `${this.eupRoutesService.rx.saveUrl}`;
		return this.http.post(url, rxModel);
	}

	public getUserSettings(contactId: number): Observable<any> {
		const url = `${this.eupRoutesService.rx.getUserSettingsUrl}/${contactId}`;
		return this.http.get(url);
	}

	public rxContactConfig(contactId: number): Observable<any> {
		const url = `${this.eupRoutesService.rx.rxContactConfigUrl}/${contactId}`;
		return this.http.get(url);
	}

	public getRxById(RxID: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getRxByIdUrl}/${RxID}`;
		return this.http.get(url);
	}

	public getRxByOrderId(orderId: number, langCode: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getRxByOrderIdUrl}`;
		return this.http.post(url, { id: orderId, langCode: langCode, roleType: this.globalSettings.get().roleType });
	}

	public getPatientByUID(patientUID: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getPatientByUIDUrl}/${patientUID}`;
		return this.http.get(url);
	}

	public checkPatientExistBy(
		businessPartnerId: number,
		firstName: string,
		lastName: string,
		chartNumber: string
	): Observable<any> {
		const url = `${this.eupRoutesService.rx.checkPatientExistByUrl}`;
		return this.http.post(
			url,
			{ companyId: businessPartnerId, firstName: firstName, lastName: lastName, chartNumber: chartNumber },
			undefined,
			true,
			false
		);
	}

	private GetOrderInformationConfiguration(labId: number) {
		const url = `${this.eupRoutesService.orderInformation.configuration}${labId}`;
		return this.http.post(url, {}, undefined, true, false);
	}

	private saveOrderRxForm(rxFormJson: string, orderId: number) {
		const url = `${this.eupRoutesService.rxForm.saveOrderRxFormUrl}`;
		return this.http.post(
			url,
			{ orderId: orderId, rxFormJson: rxFormJson, updateNotes: this.isUpdatingNotes },
			undefined,
			true,
			false
		);
	}

	private SaveAndProceedOrder(rxFormJson: string, orderId: number) {
		const url = `${this.eupRoutesService.lab.proceedToMilling}`;
		return this.http.post(url, { orderId: orderId, rxFormJson: rxFormJson }, undefined, true, false);
	}

	private saveAndProceedToModeling(rxFormJson: string, orderId: number) {
		const url = `${this.eupRoutesService.lab.proceedToModeling}`;
		return this.http.post(url, { rxFormJson: rxFormJson, orderId: orderId }, undefined, true, false);
	}

	public setReadyForDownloadAsDownloadedForLab(orderId: number) {
		const url = this.eupRoutesService.orders.setReadyForDownloadAsDownloadedForLab;
		return this.http.post(url, { orderId: orderId }, undefined, true, false);
	}

	private SaveAndRescanOrder(rxFormJson: string, orderId: number, rejectionReason: number, emailAddress: string) {
		const url = `${this.eupRoutesService.lab.backToScanning}`;
		return this.http.post(url, { orderId, rxFormJson, rejectionReason, emailAddress }, undefined, true, false);
	}	

	public processSendToLab(orderId: number) {
		const url = `${this.eupRoutesService.lab.processSendToLab}?orderHeaderId=${orderId}`;
		return this.http.get(url);
	}

	public handleMessageFromRx(event: any) {
		if (event.origin === new URL(this.eupRoutesService.rx.onlineOrthoViewerUrl).origin) {
			return;
		}

		if (event.origin !== this.eupRoutesService.rxUIUrl) {
			console.warn(`Illegal event from Rx to EUP - access denied. origin url: ${event.origin}`);
			return;
		}

		if (!event || !event.data || !event.data.eventId) {
			console.warn(`Illegal event from Rx to EUP: ${JSON.stringify(event.data)}`);
			return;
		}

		switch (event.data.eventId) {
			case RxiFrameEvents.GetAvailableCaseTypes:
				this.getAvailableCaseTypes(event.data.contactId, event.data.companyId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.LoadConfiguration:
				this.configuration(
					event.data.businessPartnerId,
					event.data.isLabOrTechnician,
					event.data.langCode
				).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.SaveRx:
				this.save(event.data.RxModel).subscribe(
					(result: any) => {
						this.postToRxiFrame(event.data.eventId, result);
					},
					(errorMessage: any) => {
						this.postToRxiFrame(event.data.eventId, { errorMessage });
					}
				);
				break;
			case RxiFrameEvents.LoadUserSettings:
				this.getUserSettings(event.data.contactId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.LoadContactConfiguration:
				this.rxContactConfig(event.data.contactId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.GetRxById:
				this.getRxById(event.data.RxID).subscribe((result: any) => {
					this.labRxExtensionService.isOrderDirectToLab = result.Result.Order.DirectToLab;
					this.labRxExtensionService.initOrderInformation(result.Result.OrderInformation);
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.GetRxByOrderId:
				this.getRxByOrderId(event.data.OrderID, event.data.langCode).subscribe((result: any) => {
					this.labRxExtensionService.isOrderDirectToLab = result.Result.Order.DirectToLab;
					this.labRxExtensionService.initOrderInformation(result.Result.OrderInformation);
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.GetPatientByUid:
				this.getPatientByUID(event.data.patientUID).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.CheckPatientExistBy:
				this.checkPatientExistBy(
					event.data.businessPartnerId,
					event.data.firstName,
					event.data.lastName,
					event.data.chartNumber
				).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.RxSaveEventToiFrameParentNavigateBack:
				if (event.data.data && event.data.data.SaveRxSucceeded) {
					this.googleAnalyticsService.hitEvent('Rx', 'Save Rx');
				}
				this.HomeLinkClickEvent.emit(null);
				break;
			case RxiFrameEvents.RxSaveEventToiFrameParentCanceled:
				this.IsSavingRx = false;
				break;
			case RxiFrameEvents.RxLoadCompleted:
				this.isRxIframeLoaded = true;
				break;
			case RxiFrameEvents.GetOrderInformationConfiguration:
				this.GetOrderInformationConfiguration(event.data.labId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.SaveOrderRxForm:
				this.saveOrderRxForm(event.data.rxFormJson, event.data.orderId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});

				this.isUpdatingNotes = false;
				break;
			case RxiFrameEvents.GetOrderToSaveAndProceed:
				this.SaveAndProceedOrder(event.data.rxFormJson, event.data.orderId).subscribe((result: any) => {
					const localUpdatedDate = new Date(
						Number(result.Result.eventResponse.Utc.replace('/Date(', '').replace(')/', ''))
					);
					this.LabOrderProceededSuccessfullyEvent.emit({
						dateUpdated: localUpdatedDate,
						millingNumber: result.Result.savedRx.OrderInformation.NumOfModels,
						additionalDiesPerTooth: result.Result.savedRx.OrderInformation.AdditionalDies
					});
				});
				break;
			case RxiFrameEvents.GetOrderToSaveAndProceedToModeling:
				this.saveAndProceedToModeling(event.data.rxFormJson, event.data.orderId).subscribe((result: any) => {
					this.labOrderProceedToModelingSucceedEvent.emit();
				});
				break;
			case RxiFrameEvents.GetOrderToSaveAndRescan:
				this.SaveAndRescanOrder(
					event.data.rxFormJson,
					event.data.orderId,
					event.data.additionalData.rejectionReason,
					event.data.additionalData.emailAddress
				).subscribe((result: any) => {
					this.IsSavingRx = false;
					this.LabOrderRescanSuccessfullyEvent.emit(result.Result);
				});
				break;
			case RxiFrameEvents.GetLabNotes:
				this.newNoteObservable$.next(event.data.data);
				break;
			case RxiFrameEvents.SetLabNote:
				this.newNoteObservable$.next([event.data.data]);
				break;
			case RxiFrameEvents.RelaodWithoutUserConfirmation:
				this.ReloadWindow.emit(event.data.data);
				break;
			default:
				console.error(`Illegal event id from Rx to EUP: ${event.data.eventId}`);
		}
	}

	public postToRxiFrame(eventId: RxiFrameEvents, result: any): void {
		const myCustomData = { eventId: eventId, data: result };
		this.PostMessageEvent.emit(myCustomData);
	}

	public getExportGalleryFile(orderId: string, patientName: string): void {
		const params = new HttpParams()
			.set('orderId', orderId)
			.set('assetReferenceType', AssetReferenceType.Fms_capture.toString());
		this.downloadFileService
			.getFile(this.eupRoutesService.orders.getScreenshotDownloadLink, { params }, patientName.replace(/\s/g, ''))
			.subscribe({
				error: (e) => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_screenshots_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				}
			});
	}

	public uploadCaptureFile(orderId: string, captureObj: object) {
		const zip = new JSZip();
		const folder = zip.folder(Object.keys(captureObj)[0].slice(0, Object.keys(captureObj)[0].lastIndexOf('_')));

		for (const imgKey in captureObj) {
			if (captureObj.hasOwnProperty(imgKey)) {
				folder.file(imgKey + '.jpg', captureObj[imgKey].split('base64,')[1], { base64: true });
			}
		}

		zip.generateAsync({ type: 'blob' }).then((content) => {
			const formData = new FormData();
			formData.append('file_upload', content, 'ID' + orderId + '_2Dgallery.zip');
			this.http.post(this.eupRoutesService.orders.uploadCapture + `?orderId=${orderId}`, formData, {}, false, false).subscribe({
				next: (result) => {
					this.eupToastService.info('', this.translateService.instant('Orders.Capture_Saved'));
				},
				error: (reason) => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Orders.Capture_Upload_Failed'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error });
				}
			});
		});
	}

	public printOrdersRx(orderIds: string): Observable<any> {
		const endpoint = escape(this.eupRoutesService.serverUrl);
		const companyId = this.globalSettings.get().selectedCompanyId;
		const cacheBusterDate = new Date();
		const cacheBuster = cacheBusterDate.getTime();

		const params = `?v=${cacheBuster}#/?Endpoint=${endpoint}&OrderIds=${orderIds}&RxForm&CompanyID=${companyId}&ApplicationMode=WebRx&Language=${this.globalSettings.getLanguage().code}&eupAsEndpoint=true&printFormat=${this.globalSettings.getPrintformat()}`;
		this.printUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
			[this.eupRoutesService.rx.printRxUrl, params].join('')
		);
		this.printUrlObservable.next(this.printUrl);

		const url = `${this.eupRoutesService.orders.updatePrintedOrdersUrl}`;
		return this.http.post(url, { orderIds: orderIds }, undefined, true, false);
	}

	getOrderInformation(): any {
		return this.labRxExtensionService.getOrderInformation();
	}
}
