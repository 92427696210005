import { Injectable } from '@angular/core';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { FileInfo, FileInfoStatus, DownloadFileInfo } from './fileInfo.model';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { CreateOrthodonticsExportFileRequestDto, CreateRestorativeExportFileRequestDto } from '../generalInterfaces';
import { NanobarService } from '../../core/nanobar.service';
import { RxService } from '../../rx/rx.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { RoleTypeEnum } from '../../shared/enums';
import * as AT from '../../shared/actionTypes';
import { EupObserver } from '../../core/eupObserver.service';

@Injectable()
export class DownloadNotificationService {
	private observable1$: Observable<FileInfo[]>;
	private subscription;
	private files: FileInfo[] = [];
	private interval: number = 5 * 1000;

	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private nanobarService: NanobarService,
		private rxService: RxService,
		private globalSettings: GlobalSettingsService,
		private eupObserver: EupObserver
	) {}

	getObservable() {
		if (this.observable1$ === undefined) {
			this.observable1$ = this.createObservable1();
			this.subscription = this.createObservable2().subscribe();
		}
		return this.observable1$;
	}

	private createObservable1() {
		return Observable.of(this.files);
	}

	private createObservable2() {
		return Observable.timer(0, this.interval)
			.flatMap(() => {
				const parallelWorkOrderIds = this.getParallelWorkOrderIds();

				// Check if no need to send request to server
				if (parallelWorkOrderIds.length === 0) {
					return Observable.of([]);
				}

				const payload = { parallelWorkOrderIds };
				return this.http.post(
					this.eupRoutesService.orders.getExportStatusBulk,
					payload,
					undefined,
					false,
					false
				);
			})
			.map((files: FileInfo[]) => {
				const result = files.reduce((acc, file) => ({ ...acc, [file.parallelWorkOrderId]: file }), {});
				// update the files
				this.files.forEach((file, index, array) => {
					const id = file.parallelWorkOrderId;
					array[index] = { ...array[index], ...result[id] };
				});

				// remove only one file which is ready in givin time
				const idx = this.files.findIndex(
					(file) => file.status === FileInfoStatus.Completed && file.downloaded === false
				);
				if (idx > -1) {
					this.downloadFile(this.files[idx]);
				}

				return this.files;
			});
	}

	private getParallelWorkOrderIds(): number[] {
		const result = this.files.filter(
			(file) => file.status !== FileInfoStatus.Failed && file.status !== FileInfoStatus.Canceled
		);
		return result.map((file) => file.parallelWorkOrderId);
	}

	downloadFile(file: DownloadFileInfo): void {
		this.nanobarService.start();
		const url =
			file.workOrderId && file.workOrderId > 0
				? this.eupRoutesService.orders.getStlFileByWorkOrder
				: this.eupRoutesService.orders.getStlFile;
		const { parallelWorkOrderId, orderId, orderDetailsId } = file;
		file.downloaded = true;
		const queryParams =
			file.workOrderId && file.workOrderId > 0
				? `${url}?workOrderId=${file.workOrderId}&orderId=${orderId}&orderDetailsId=${orderDetailsId}`
				: `${url}?parallelWorkOrderId=${parallelWorkOrderId}&orderId=${orderId}&orderDetailsId=${orderDetailsId}`;

		this.downloadFileFromUrl(queryParams);

		this.nanobarService.stop();
		if (!file.workOrderId) {
			const index = this.files.findIndex((f) => f.parallelWorkOrderId === file.parallelWorkOrderId);
			this.files.splice(index, 1);
		}

		const settings = this.globalSettings.get();
		if (settings.roleType === RoleTypeEnum.Lab) {
			this.rxService.setReadyForDownloadAsDownloadedForLab(file.orderId).subscribe(() => {
				this.eupObserver.emit({
					action: AT.DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED,
					payload: null
				});
			});
		}
	}

	private downloadFileFromUrl(url: string) {
		const iframe = document.createElement('iframe');
		iframe.src = url;
		iframe.style.display = 'none';
		iframe.tabIndex = -1;
		document.body.appendChild(iframe);
		setTimeout(() => { document.body.removeChild(iframe); }, 100000);
	}

	addFile(
		parallelWorkOrderId: number,
		order: CreateOrthodonticsExportFileRequestDto | CreateRestorativeExportFileRequestDto
	) {
		const req = {
			parallelWorkOrderId,
			orderId: order.orderId,
			orderDetailsId: 0,
			progress: 0,
			creationTime: Date.now(),
			downloaded: false,
			requestData: order,
			status: FileInfoStatus.Active
		} as FileInfo;

		const idx = this.files.findIndex((file) => file.parallelWorkOrderId === parallelWorkOrderId);
		if (idx === -1) {
			this.files.push(req);
		}
	}

	removeFile(orderId: number) {
		const index = this.files.findIndex((file) => file.orderId === orderId);
		this.files.splice(index, 1);
	}

	clear(): void {
		this.observable1$ = undefined;
		this.subscription.unsubscribe();
		this.files.splice(0, this.files.length);
	}
}
