﻿import { Injectable } from '@angular/core';

@Injectable()
export class PlatformDetectorService {
	private isIEVal: boolean;
	private isEdgeVal: boolean;
	private isMacVal: boolean;
	private isWindowsVal: boolean;
	private isiPadVal: boolean;
	private isSafariVal: boolean;

	get isIE() {
		return this.isIEVal;
	}

	get isSafari() {
		return this.isSafariVal;
	}

	get isEdge() {
		return this.isEdgeVal;
	}

	get isMac() {
		return this.isMacVal;
	}

	get isWindows() {
		return this.isWindowsVal;
	}

	get isTablet() {
		return !this.isMacVal && !this.isWindowsVal;
	}

	get isiPad() {
		return this.isiPadVal;
	}

	initialize(): void {
		const userAgent = window.navigator.userAgent;
		const platform = window.navigator.platform;

		this.isiPadVal = /iPad/i.test(userAgent);
		this.isEdgeVal = /Edge/i.test(userAgent);
		this.isIEVal = /MSIE|Trident\//i.test(userAgent);

		this.isWindowsVal = /Win32/i.test(platform);
		this.isMacVal = /Mac68K|MacPPC|MacIntel/i.test(platform);

		// Safari Contains just Safari, Chrome contains both
		this.isSafariVal = userAgent.indexOf('Safari') > 1 && userAgent.indexOf('Chrome') === -1;
	}
}
