var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
/* tslint:disable-next-line */
import jstz from 'jstz';
import 'moment-timezone';
var Utils = /** @class */ (function () {
    function Utils(translateService) {
        this.translateService = translateService;
    }
    Utils.prototype.clearObservablesSubscriptions = function (obj) {
        for (var propertyName in obj) {
            if (obj.hasOwnProperty(propertyName) && obj[propertyName] instanceof Subscription) {
                obj[propertyName].unsubscribe();
            }
        }
    };
    Utils.prototype.lowerCaseProps = function (obj) {
        var parsedObj = {};
        Object.keys(obj).forEach(function (k) {
            parsedObj[k.toLowerCase()] = obj[k];
        });
        return parsedObj;
    };
    Utils.prototype.formatString = function (source) {
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        return source.replace(/{(\d+)}/g, function (match, num) {
            return typeof params[num] !== 'undefined' ? params[num] : match;
        });
    };
    Utils.prototype.translateKeyValueList = function (source, keyValueList) {
        var _this = this;
        return keyValueList.map(function (i) { return (__assign({}, i, { value: _this.translateService.instant(source + "." + i.key) })); });
    };
    return Utils;
}());
export { Utils };
export function formatDate(value, overrideFormat, settingsDateFormat) {
    var defaultFormat = 'MM/DD/YYYY';
    var timezone = jstz.determine();
    var momentObject = moment(value);
    if (momentObject.isValid()) {
        var format = overrideFormat;
        if (!overrideFormat) {
            format = settingsDateFormat;
            if (!format) {
                format = defaultFormat;
            }
        }
        return momentObject.tz(timezone.name()).format(format);
    }
    else {
        return '';
    }
}
