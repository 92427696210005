import { GlobalSettingsService } from './core/globalSettings.service';
import { RouterInterceptorService } from './core/routerInterceptor.service';
import { PlatformDetectorService } from './core/platformDetector.service';
import { TranslateService } from '@ngx-translate/core';
import { EupHttpHandler } from './core/eupHttpHandler.service';
import { EupRoutesService } from './core/eupRoutes.service';
import { AppearanceService } from './core/appearance.service';
import { Appearance } from './shared/enums';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
var AppConfig = /** @class */ (function () {
    function AppConfig() {
    }
    Object.defineProperty(AppConfig, "getSupportedLanguages", {
        get: function () {
            return this.supportedLangs;
        },
        enumerable: true,
        configurable: true
    });
    AppConfig.supportedLangs = [
        'de-DE',
        'es-ES',
        'fr-FR',
        'it-IT',
        'ja-JP',
        'ko-KR',
        'th-TH',
        'zh-CN',
        'zh-TW',
        'en-US'
    ];
    return AppConfig;
}());
export { AppConfig };
var AppComponent = /** @class */ (function () {
    function AppComponent(http, translateService, globalSettingsService, routerInterceptor, platformDetector, appearanceService, eupRoutesService, downloadNotificationService) {
        var _this = this;
        this.http = http;
        this.translateService = translateService;
        this.globalSettingsService = globalSettingsService;
        this.routerInterceptor = routerInterceptor;
        this.platformDetector = platformDetector;
        this.appearanceService = appearanceService;
        this.eupRoutesService = eupRoutesService;
        this.downloadNotificationService = downloadNotificationService;
        this.supportedLangs = AppConfig.getSupportedLanguages;
        this.browserLang = this.translateService.getBrowserLang();
        this.browserCultureLang = this.translateService.getBrowserCultureLang();
        this.lang = 'en-US';
        this.lightThemeClass = false;
        var globalSettings = globalSettingsService.get();
        routerInterceptor.initialize();
        platformDetector.initialize();
        this.windowsClass = platformDetector.isWindows;
        this.ieClass = platformDetector.isIE;
        this.edgeClass = platformDetector.isEdge;
        this.isTabletClass = platformDetector.isTablet;
        this.iPadClass = platformDetector.isiPad;
        this.macClass = platformDetector.isMac;
        this.translateService.setDefaultLang('en-US');
        // try to set the language according to the selected language of the logged in user
        if (globalSettings && globalSettings.selectedLanguage) {
            this.setLang(globalSettings.selectedLanguage.code);
        }
        else {
            // n order to detect browser language for ie & edge needs http request
            if (platformDetector.isIE || platformDetector.isEdge) {
                this.setLang('en-US');
                // const getLangCode = () =>
                // 	this.http.get(this.eupRoutesService.login.browserLanguageCode, undefined, false, false);
                // this.eupRoutesService.doAfterInit(getLangCode).subscribe(
                // 	(res: any) => {
                // 		this.setLang(res.detectedCulture);
                // 	},
                // 	() => {
                // 		this.setLang('en-US');
                // 	}
                // );
            }
            else {
                if (this.browserCultureLang && this.supportedLangs.indexOf(this.browserCultureLang) > -1) {
                    this.setLang(this.browserCultureLang);
                }
                else {
                    if (this.browserLang &&
                        this.supportedLangs.filter(function (sl) { return sl.substring(0, 2) === _this.browserLang; })[0]) {
                        this.setLang(this.supportedLangs.filter(function (sl) { return sl.substring(0, 2) === _this.browserLang; })[0]);
                    }
                }
            }
        }
        this.translateService.onLangChange.subscribe(function (event) {
            if (event.lang) {
                _this.lang = event.lang;
            }
        });
        this.appearanceService.appearanceChanged$.subscribe(function (theme) {
            _this.lightThemeClass = theme === Appearance.Light;
        });
        this.files$ = this.downloadNotificationService.getObservable();
    }
    AppComponent.prototype.setLang = function (lang) {
        this.lang = lang;
        var globalSettings = this.globalSettingsService.get();
        if (globalSettings && globalSettings.selectedLanguage) {
            this.translateService.use(globalSettings.selectedLanguage.code);
        }
        else {
            this.translateService.use(this.lang);
        }
    };
    return AppComponent;
}());
export { AppComponent };
var BrowserInfo = /** @class */ (function () {
    function BrowserInfo() {
    }
    return BrowserInfo;
}());
export { BrowserInfo };
