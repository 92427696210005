﻿import {
	Component,
	Output,
	EventEmitter,
	ElementRef,
	ViewChild,
	Input,
	ContentChildren,
	QueryList,
	AfterContentInit,
	AfterContentChecked,
	OnInit,
	OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SortableHeaderColumn } from '../sortableHeaderColumn/sortableHeaderColumn.component';
import { PagedQueryParameters, PagedQueryResult, ITableService } from '../generalInterfaces';
import { SortEventEmitterService } from '../sortableHeaderColumn/sortEventEmitter.service';
import { Utils } from '../utils.service';
import { OrderByPipe } from '../orderBy.pipe';
import { ContextParams } from '../../core/globalSettings.service';
import * as AT from '../actionTypes';
import { EupObserver } from '../../core/eupObserver.service';
import { SpinnerService } from '../../core/spinner/spinner.service';

@Component({
	selector: 'eup-tbl',
	templateUrl: './table.component.html',
	providers: [ SortEventEmitterService ],
	styleUrls: [ './table.component.scss' ]
})
export class TableComponent implements AfterContentChecked, AfterContentInit, OnDestroy, OnInit {
	@Output() onRowsChanged = new EventEmitter();
	@Output() onRowClicked = new EventEmitter<Element>();
	@Input() pagingOnScroll = false;
	@Input() preventOverflowX = false;
	isScrollable = false;
	el: ElementRef;

	@ContentChildren(SortableHeaderColumn) private cols: QueryList<SortableHeaderColumn>;
	@ViewChild('innerTable') private innerTable: ElementRef;
	@ViewChild('innerTableDiv') private innerTableDiv: ElementRef;
	private sortSubscription: Subscription;

	pagingParams = new PagedQueryParameters();
	shouldGetMoreData = true;
	hasAllData = false;
	pagingService: ITableService;
	queryResult: PagedQueryResult;
	isFinishedDataBinding: boolean;
    eupObserverSubscription: Subscription;

	constructor(
		private emitter: SortEventEmitterService,
		private utils: Utils,
		el: ElementRef,
		private eupObserver: EupObserver,
		private spinnerService: SpinnerService
	) {
		this.el = el;
		this.sortSubscription = emitter.sortClicked$.subscribe((sortParams: PagedQueryParameters) => {
			this.onSort(sortParams);
		});
	}

	ngOnInit() {
		const observer = {
			next: (value) => {
				switch (true) {
					case value.action === AT.DOWNLOAD_NOTIFICATION_SERVICE_DOWNLOADED:
					case value.action === AT.EDIT_WITH_ITERO_LAB_SUCCESS:
						this.researchRows();
						break;
					default:
						break;
				}
			}
		};
		this.eupObserverSubscription = this.eupObserver.subscribe(observer);
	}

	showNoResults(): boolean {
		return (
			this.isFinishedDataBinding &&
			this.pagingParams.pageNumber === 1 &&
			(!this.pagingService.getRows() || (this.pagingService.getRows() && !this.pagingService.getRows().length))
		);
	}

	setTableParams(service: ITableService, initialQuery: PagedQueryParameters) {
		this.pagingParams = initialQuery;
		this.pagingService = service;
	}

	updateTableContext(params: ContextParams): void {
		this.pagingParams.companyId = params.companyId;
		this.pagingParams.doctorId = params.doctorId;

		this.resetPagedQuery();
		this.searchRows();
	}

	sortRows() {
		OrderByPipe.prototype.transform(
			this.pagingService.getRows(),
			this.pagingParams.sortField,
			this.pagingParams.sort
		);
		this.scrollToTop();
		this.onRowsChanged.emit();
	}

	filterRows(filter: string) {
		// don't do anything if the filter hasn't changed
		if (this.pagingParams.filter === filter) {
			return;
		}

		this.resetPagedQuery();
		this.pagingParams.filter = filter;
		this.searchRows();
	}

	reload() {
		this.resetPagedQuery();
		this.searchRows();
	}

	searchRows(): void {
		this.isFinishedDataBinding = false;
		this.shouldGetMoreData = false;
		this.pagingService.requestPage(this.pagingParams).subscribe((queryResult: PagedQueryResult) => {
			this.searchRowsCompleted(queryResult);
		});
	}

	private researchRows(): void {
		this.spinnerService.start();
		setTimeout(() => {
            this.pagingParams.pageNumber = 1;
			this.searchRows();
			this.spinnerService.stop();
		}, 10000);
	}

	private searchRowsCompleted(queryResult: PagedQueryResult) {
        this.queryResult = queryResult;

		// for new queries (first page) update UI
		// only once we got new data
		if (this.pagingParams.pageNumber === 1) {
			this.pagingService.getRows().length = 0;
			this.scrollToTop();
		}

		// server returns up to pageSize+1 results, so only add pageSize items to the table data
		Array.prototype.push.apply(this.pagingService.getRows(), queryResult.results.slice(0, this.pagingParams.pageSize));

		// if there are more pages, allow sending additional queries
        if (queryResult.results.length > this.pagingParams.pageSize-1) {
			this.shouldGetMoreData = true;
			this.hasAllData = false;
		} else {
			// client received all the pages for the current query
			this.hasAllData = true;
		}

		this.isFinishedDataBinding = true;
		this.onRowsChanged.emit();
	}

	/* tslint:disable:no-unused-variable */
	onScroll() {
		this.pagingParams.pageNumber++;
		this.searchRows();
	}

	private onSort(sortParams: PagedQueryParameters): void {
		this.deactivateAllCols(sortParams.sortField);

		this.pagingParams.sort = sortParams.sort;
		this.pagingParams.sortField = sortParams.sortField;

		// client already received all the pages so we can sort data on the client
		// or no paging is required
		if (this.hasAllData || !this.pagingOnScroll) {
			// sorts the patients array in place
			this.sortRows();
		} else {
			// client doesn't have all the data so we need to send a new request to the server
			this.resetPagedQuery();
			this.searchRows();
		}
	}

	private resetPagedQuery(): void {
        this.pagingParams.pageNumber = 1;
		this.hasAllData = false;
	}

	// contentChildren are set
	ngAfterContentInit() {}

	// Respond after Angular checks the content projected into the component
	ngAfterContentChecked() {
		const scrollbarWidth = this.getScrollWidth();
		this.isScrollable = scrollbarWidth > 0;
	}

	ngOnDestroy() {
		// prevent memory leak when component destroyed
		this.utils.clearObservablesSubscriptions(this);
		this.eupObserverSubscription.unsubscribe();
	}

	private deactivateAllCols(colNameToSkip: string) {
		this.cols.filter((c) => c.name !== colNameToSkip).forEach((col) => (col.active = false));
	}

	private onRowClickedEvent(event: Event, row: HTMLTableRowElement): void {
		const disableActive = row.classList.contains('active');
		let otherActiveRows = row.parentElement.getElementsByClassName('active');

		if (otherActiveRows.length > 0) {
			otherActiveRows[0].classList.remove('active');
		}

		if (!disableActive) {
			row.classList.add('active');
		}

		this.onRowClicked.emit(row);
	}

	onTableClickedEvent(event: Event): void {
		const eventTarget = <any>(event.srcElement || event.target);
		const parentsList = this.getParents(eventTarget, 'tbody');
		if (parentsList.filter((elem) => elem.localName === 'tbody').length > 0) {
			this.onRowClickedEvent(event, parentsList.filter(
				(elem) => elem.localName === 'tr'
			)[0] as HTMLTableRowElement);
		}
		event.preventDefault();
		event.stopPropagation();
	}

	private getParents(el: Element, parentSelector: string): Element[] {
		const parents = <Element[]>[];
		let parent = el.parentElement;

		while (parent && parent.localName !== parentSelector) {
			const child = parent;
			parents.push(child);
			parent = child.parentElement;
		}
		if (parent) {
			parents.push(parent); // push that parent I wanted to stop at
		}
		return parents;
	}

	private getScrollWidth(): number {
		if (this.innerTableDiv && this.innerTable) {
			return this.innerTableDiv.nativeElement.offsetWidth - this.innerTable.nativeElement.offsetWidth;
		}
		return 0;
	}

	private scrollToTop() {
		this.innerTableDiv.nativeElement.scrollTop = 0;
	}

	public getInnerTableDiv(): ElementRef {
		return this.innerTableDiv;
    }

    public setCurrentTableTotal(newValue) {
        if (!isNaN(Number(newValue)) || newValue === null) {
	        this.pagingParams.existingTotal = newValue;
        }
    }

	public resetCurrentTableTotal() {
		this.pagingParams.existingTotal = this.pagingParams.defaultTotalValue;
	}
}
