﻿import { Injectable, Compiler } from '@angular/core';
import { Router, NavigationStart, NavigationError, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { TitleService } from './title.service';
import { GoogleAnalyticsService } from './googleAnalytics.service';
import { AppearanceService, DEFAULT_THEME } from './appearance.service';
import { Appearance } from '../shared/enums';

@Injectable()
export class RouterInterceptorService {
	private prevUrl: string;
	private navigationStartTime: number;

	get previousRoute() {
		return this.prevUrl;
	}

	constructor(
		private router: Router,
		private compiler: Compiler,
		private spinner: SpinnerService,
		private titleService: TitleService,
		private activatedRoute: ActivatedRoute,
		private googleAnalyticsService: GoogleAnalyticsService,
		private appearanceService: AppearanceService
	) {}

	initialize(): void {
		this.router.events.subscribe((val: any) => {
			if (val instanceof NavigationStart) {
				this.handleNavigationStart(val);
			}

			if (val instanceof NavigationEnd) {
				this.handleNavigationEnd(val);
			}

			if (val instanceof NavigationError) {
				this.handleNavigationError(val);
			}
		});
	}

	handleNavigationStart(event: NavigationStart) {
		this.prevUrl = this.router.url;
		this.navigationStartTime = new Date().getTime();
		this.spinner.start(true);
	}

	handleNavigationEnd(event: NavigationEnd) {
		this.spinner.stop();

		this.googleAnalyticsService.hitPageView(event.urlAfterRedirects);
		// calculate timing
		if (this.navigationStartTime) {
			const timeElapsed = new Date().getTime() - this.navigationStartTime;
			this.googleAnalyticsService.hitPageTiming(event.urlAfterRedirects, timeElapsed);
		}

		let route = this.activatedRoute;
		while (route.firstChild) {
			route = route.firstChild;
		}
		route.data.subscribe((data: Data) => {
			if (!data['skipTitle']) {
				this.titleService.setTitle(data['title']);
			}

			const theme = data['theme'] || DEFAULT_THEME;
			this.appearanceService.toTheme(theme);
		});
	}

	// if any error happens during changing of routes (session expired / exception)
	// then redirect to login page - angular2 doesn't supply the http status of the response
	// so we can't act accordingly
	handleNavigationError(event: NavigationError) {
		this.spinner.stop();
		// angular cache failed component requests so we have to clear the cache
		// otherwise next time angular will request a component that failed to load
		// will throw exception without even making a request to the server
		this.compiler.clearCache();

		// error in login page - reload current page (angular2 doesn't support reloading components yet)
		if (event.url === '/home' && this.prevUrl === '/login') {
			window.location.reload();
		} else {
			// any other page
			this.router.navigate([ '/login' ]);
		}
	}
}
