import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PagedQueryParameters, PagedQueryResult } from '../../shared/generalInterfaces';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { AssetReferenceType } from '../../shared/enums';
import { DownloadFileService } from '../../shared/downloadFile.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { ToothNumberingSystem } from '@shared/enums';

@Injectable()
export class OrdersService {
	public orderObservable$: Observable<Order>;
	OrderUpdated = new EventEmitter<any>();
	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private downloadFileService: DownloadFileService,
		private translateService: TranslateService,
		private notificationService: NotificationService,
		private httpService: EupHttpHandler) { }

	public getUndeliveredOrders(query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.getOrders(this.eupRoutesService.orders.undeliveredUrl, query);
	}

	public getDeliveredOrders(query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.getOrders(this.eupRoutesService.orders.deliveredUrl, query);
	}

	public deleteRx(rxId: string): Observable<any> {
		return this.http.delete(`${this.eupRoutesService.orders.deleteRxUrl}/${rxId}`, { responseType: 'text' });
	}

  public startProtocol(link: string, createNewiFrame: boolean): void {
	let ifrm = undefined;
	if (!createNewiFrame) {
		ifrm = document.getElementsByTagName('iframe')[0];
	}
		if (ifrm === null || ifrm === undefined) {
			ifrm = document.createElement('iframe');
		}
		ifrm.setAttribute('src', link);
		ifrm.style.display = 'none';
		document.body.appendChild(ifrm);
	}

	private getOrders(url: string, query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.http.get(url, { params: query.createSearchParams() }, true, true);
	}


	public getExportGalleryFile(orderId: string, patientName: string): void {
		const params = new HttpParams()
			.set('orderId', orderId)
			.set('assetReferenceType', AssetReferenceType.Fms_capture.toString());

		this.downloadFileService.getFile(this.eupRoutesService.orders.getScreenshotDownloadLink, { params }, patientName.replace(/\s/g, '')).subscribe(
			{
				error: e => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_screenshots_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				}
			}
		);
	}

	public getFileStorage(orderId: number): Observable<number> {
		const params = new HttpParams().set('orderId', orderId.toString());
		return this.httpService.get(this.eupRoutesService.orders.getFileStorage, { params }, true, true)
			.catch((err) => this.handleError(err));
	}

	public isFailedOrderExists(selectedCompanyId: number, selectedDoctorId: number): Observable<any> {
		const params = new HttpParams()
			.set('companyId', selectedCompanyId.toString())
			.set('doctorId', selectedDoctorId.toString());
		return this.http.get(this.eupRoutesService.orders.isFailedOrderExistsUrl, { params: params }, true, false);
	}

	public getLabOrderById(orderId: string, doctorId: string, companyId: string): void {
		const params = new HttpParams().set('orderId', orderId).set('doctorId', doctorId).set('companyId', companyId);
		this.orderObservable$ = this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: params });
	}


	private handleError(err) {
		this.notificationService.show(
			this.translateService.instant('Errors.Error_download_file_title'),
			this.translateService.instant('Errors.Error_500_Title'),
			{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
		);

		return Observable.throw(err);
	}
	public updateLabOrderById(orderId: string, doctorId: string, companyId: string, action: (orderToEdit: Order) => void): void {
		const params = new HttpParams().set('orderId', orderId).set('doctorId', doctorId).set('companyId', companyId);
		this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: params }).subscribe((order: Order) => {
			if (this.OrderUpdated) {
				if (action) {
					action(order);
				}
				this.OrderUpdated.emit(order);
			}
		});
	}
}

export class Order {
	id: number;
	guid: string;
	patientName: string;
	chartNumber: string;
	scanDate: string;
	isTakenByScanner: boolean;
	status: string;
	companyId: number;
	doctorId: number;
	caseTypeDescription: string;
	caseTypeId: number;
	isOrder: boolean;
	canOpenFile: boolean;
	canExportFile: boolean;
	canViewRx: boolean;
	canChangeCaseType: boolean;
	canWebExport: boolean;
	canRestorativeExport: boolean;
	canSendToModeling?: boolean;
	canSendToMilling?: boolean;
	canEditRx?: boolean;
	canReturnToDoctor?: boolean;
	canOpenWithItero?: boolean;
	canOpenWithOrthoCad?: boolean;
	outcomeSimulatorEnabled: boolean;
	outcomeSimulatorLink: string;
	iGoCaseAssessmentEnabled: boolean;
	iGoCaseAssessmentLink: string;
	iGoOutcomeSimulatorEnabled: boolean;
	iGoOutcomeSimulatorLink: string;
	iGoProgressAssessmentEnabled: boolean;
	progressAssessmentEnabled: boolean;
	progressAssessmentLink: string;
	openFileLink: string;
	exportFileLink: string;
	caseAssessmentLink: string;
	progressTrackingLink: string;
	parallelWorkOrderId: string;
	isFailedToSend: boolean;
	isOrthoOrder: boolean;
	isAlta: boolean;
	lastBowId: number;
	lastDetailsId: number;
	isCaptureFileExist: boolean;
	isOrderPrinted: boolean;
	isOrderDownloaded: boolean;
	isNiriFileExist: boolean;
	toothNumberingSystem: ToothNumberingSystem;
}

export class CaseTypePrefixes {
	public static ALTA = 'ALTA - ';
}
