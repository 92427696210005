﻿import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
/* tslint:disable-next-line */
import jstz from 'jstz';
import 'moment-timezone';

@Injectable()
export class Utils {

	constructor(
		private translateService: TranslateService
	) { }

	public clearObservablesSubscriptions(obj: any): void {
		for (const propertyName in obj) {
			if (obj.hasOwnProperty(propertyName) && obj[propertyName] instanceof Subscription) {
				(obj[propertyName] as Subscription).unsubscribe();
			}
		}
	}

	public lowerCaseProps(obj: any) {
		const parsedObj: any = {};
		Object.keys(obj).forEach((k) => {
			parsedObj[k.toLowerCase()] = obj[k];
		});

		return parsedObj;
	}

	public formatString(source: string, ...params: any[]): string {
		return source.replace(/{(\d+)}/g, (match, num) => {
			return typeof params[num] !== 'undefined' ? params[num] : match;
		});
    }

    public translateKeyValueList(source, keyValueList) {
	    return keyValueList.map(i => ({ ...i, value: this.translateService.instant(`${source}.${i.key}`) }));
    }
}

export function formatDate(value: string, overrideFormat: string, settingsDateFormat: string): string {
	const defaultFormat = 'MM/DD/YYYY';
	const timezone = jstz.determine();

	const momentObject: any = moment(value);
	if (momentObject.isValid()) {
		let format = overrideFormat;
		if (!overrideFormat) {
			format = settingsDateFormat;
			if (!format) {
				format = defaultFormat;
			}
		}
		return momentObject.tz(timezone.name()).format(format);
	} else {
		return '';
	}
}
